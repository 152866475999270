import { ReactNode, ComponentPropsWithoutRef } from 'react'
import * as Icon from "react-feather"
import { Oval } from 'react-loader-spinner'
import { clsx } from 'clsx';

interface Props extends ComponentPropsWithoutRef<"button">{
    disabled: boolean
    onClick: () => void
    children: ReactNode,
    hug?: boolean,
    fill?: boolean,
    loading?: boolean,
    secondary?: boolean,
    secondaryGray?: boolean,
    destructive?: boolean,
    size: "sm" | "md" | "lg" | "xl" | "2xl"
}

export const Button = ({ disabled, onClick, children, hug, loading, secondary, destructive, size, secondaryGray, ...rest }: Props) => {
    const sizes = {
        sm: 2,
        md: 2.5,
        lg: 2.5,
        xl: 3,
        "2xl": 4
    }
    
    return (
        <button
    
            className={clsx(
                `transition-all text-center text-sm font-semibold  rounded-lg py-${(sizes[size])} `,
                hug && "px-4",
                !hug && "w-full",
                !secondary && !destructive && !secondaryGray && !disabled && "text-white bg-primary-600", //no state specified, regular button
                !secondary && destructive && "bg-error-600 text-white", //destrucive and primary (not secondary),
                destructive && secondary && "bg-error-50 text-error-700", //destructive and secondary
                secondaryGray && "bg-white border border-gray-300 text-gray-700",
                disabled && 'text-white bg-primary-200'
            )}

            onClick={onClick}
            disabled={disabled}
            {...rest}
        >
            {loading ?
                <>
                    <div className='flex w-full justify-center items-center'>
                        <Oval color='#ffffff' secondaryColor='#7D7D7D' height={16} width={16} />
                    </div>
                </>
                :
                <>
                    {children}
                </>

            }
        </button>
    )
}
