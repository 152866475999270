import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ContentPage from '../../components/common/ContentPage'
import Section from '../../components/common/Section'
import TextInput from '../../components/input/TextInput'
import Select from 'react-select'
import hours from "../../utils/day_hours.json"
import { fetchUserByID, fetchBusinessByID } from '../../api/booking'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '../../store/models/userModel'
import moment from 'moment'
import { updateProfessionalProfile, updateShopProfile } from '../../utils/ShopProfileTasks'
import toast, { Toaster } from 'react-hot-toast'
import { shopSelector } from '../../store/models/shopModel'
import { showToast, uploadFile, uploadImage } from '../../utils/utilities'
import Cropper from 'react-easy-crop'
import ImageCropper from '../../components/ImageCropper'
import { Button } from '../../components/button'
import Compressor from 'compressorjs'
import { Oval } from 'react-loader-spinner'

const hoursArray: any[] = []

function ProfessionalProfile() {

  const imageRef = useRef<any>()

  const [picURL, setPicURL] = useState<any>() //cropper uses this as input, either profile or banner

  const [croppedProfilePicURL, setCroppedProfilePicURL] = useState<any>() //cropper generates this
  const [compressedProfile, setCompressedProfile] = useState<any>() //this is what is compressed and uploaded

  const [selectingImage, setSelectingImage] = useState<"banner" | "profile" | null>(null)

  const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

  const [loading, setLoading] = useState<boolean>(false)

  // refs to the different fields
  const proNameRef = useRef<any>()
  const emailRef = useRef<any>()
  const phoneNumberRef = useRef<any>()
  const usernameRef = useRef<any>()

  const { id } = useSelector(userSelector)
  const user = useSelector(userSelector)

  const dispatch = useDispatch();

  useEffect(() => {
    hours.map((hours) => {
      hoursArray.push({
        label: `${hours.standard_format} ${hours.time_of_day}`,
        value: hours.military_format
      })
    })
  }, [])

  // send the profile data to the backend
  const submit = async () => {
    setLoading(true)

    var dataToUpdate: any = {
      name: proNameRef.current?.getInputText(),
      email: emailRef.current?.getInputText(),
      phone: phoneNumberRef.current?.getInputText(),
      userName: usernameRef.current?.getInputText(),
    }

    await uploadFile(compressedProfile, `professionals/${id}/profile/profile_picture`)
      .then(async (uploadedPicURL) => {
        console.log("Profile picture URL", uploadedPicURL)

        if (uploadedPicURL) {
          dataToUpdate = {
            ...dataToUpdate,
            profilePic: uploadedPicURL,
          }

          console.log("pro object to save", dataToUpdate)
        }


        await updateProfessionalProfile({ queryKey: ["proID", id] }, dataToUpdate)
          .then(() => {
            setLoading(false)
            dispatch.User.saveUser({
              ...user,
              name: proNameRef.current?.getInputText(),
              email: emailRef.current?.getInputText(),
              phone: phoneNumberRef.current?.getInputText(),
              userName: usernameRef.current?.getInputText(),
              profilePic: uploadedPicURL,
            }
            )
            showToast("Your data has been saved", "success")
          }).catch((e) => {
            setLoading(false)
            showToast("We couldn't save your data, check your internet and try again", "error", 7000)
          })
      }).catch((e) => {
        setLoading(false)
        showToast("We couldn't save your data, check your internet and try again", "error", 7000)
      })
  }



  // load existing details from backend
  useEffect(() => {
    console.log("Loading current details...")
    async function fetchCurrentShopData() {
      await fetchUserByID({ queryKey: ["proID", id] })
        .then((data) => {
          proNameRef.current?.setValue(data?.name ? data?.name : "")
          emailRef.current?.setValue(data?.email ? data?.email : "")
          phoneNumberRef.current?.setValue(data?.phone ? data?.phone : "")
          usernameRef?.current?.setValue(data?.userName ? data?.userName : "")
          setCroppedProfilePicURL(data?.profilePic)

        })
    }
    fetchCurrentShopData()
  }, [id])

  // compress selected profile image
  useEffect(() => {
    const generateImageURL = async () => {
      let blob = await fetch(croppedProfilePicURL).then(r => r.blob());
      new Compressor(blob, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        convertTypes: ['image/png'],
        convertSize: 1000000,
        success: async (compressedResult) => {
          setCompressedProfile(compressedResult)
        },
      });
    }
    if (croppedProfilePicURL) {
      generateImageURL()
    }
  }, [croppedProfilePicURL])

  return (
    <>
      {selectingImage &&
        <div className=''>
          <div className="">
            <ImageCropper ref={imageRef} image={picURL} uploadButtonText="Choose image" aspectRatio={selectingImage === "profile" ? 1 : 1.5}
              onUpload={() => {
                if (selectingImage === "profile") {
                  setCroppedProfilePicURL(imageRef.current.getImageURL())
                }
                setSelectingImage(null)
              }}

              onCancel={() => {
                setSelectingImage(null)
              }}

            />

          </div>

        </div>
      }
      <ContentPage loadingAction={loading} title="Your profile" subtitle='Update your profile and what clients see' actionText='Save' showActionButton onClickAction={submit}>
        <div className='sm:px-6 sm:pt-6 p-4'>

          <Section title='Public profile' subtitle='Set up your profile'>
            <div className='grid sm:grid-cols-2 gap-4 w-max'>
              <TextInput
                ref={proNameRef}
                placeholder='Enter your name'
                showLabel
                label="Name"

              />
              <TextInput
                ref={usernameRef}
                placeholder='Enter your name on Wavier'
                showLabel
                label="Username"
                hint='This is what clients will see on Wavier'
              />
              <TextInput
                ref={emailRef}
                placeholder='example@something.com'
                showLabel
                label="Email address"
                type={"email"}
              />
              <TextInput
                ref={phoneNumberRef}
                placeholder='ie: +233550000000, +15555551234'
                showLabel
                label="Phone (including country code)"
              />

            </div>
          </Section>

          <Section title='Profile picture' subtitle='Choose a cute picture of you ;)'>
            <div className='flex'>

              <div className='flex flex-col w-64 gap-4'>
                <label className='text-md text-gray-600 mb-2'>Profile picture</label>
                <div className='h-64 w-64'>
                  {!croppedProfilePicURL ?
                    <>
                      <div className='flex items-center h-full w-full justify-center'>
                        <Oval color='#ffffff' secondaryColor='#7D7D7D' width={24} />
                      </div>
                    </> :
                    <>
                      <img className="h-64 w-64 rounded-lg object-cover object-center" src={croppedProfilePicURL} alt="profile pic"></img>
                    </>}
                </div>
                <input id='profile_pic' type="file" className='block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100 hover:file:cursor-pointer'
                  onChange={(event) => {
                    if (event) {
                      if (!event.target.files) return;
                      // setProfilePic(event.target.files[0]);
                      // setProfilePicURL(URL.createObjectURL(event.target.files[0]));
                      setPicURL(URL.createObjectURL(event.target.files[0]));
                      setSelectingImage("profile")

                    }
                  }} />
              </div>

            </div>
          </Section>


        </div>
        <Toaster />
      </ContentPage>
    </>
  )
}

export default ProfessionalProfile