import { initializeApp } from 'firebase/app'
import Firebase from 'firebase/compat/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import 'firebase/compat/auth'
import axios from 'axios';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)

const emulators = axios.get("http://localhost:4400/emulators")
.then((res) => {
    console.log(res);
    return res.data;
})

export const auth = getAuth(app)
export const firestoreDb = getFirestore(app)
export const storage = getStorage(app);
export const functions = getFunctions(app);


// feel free to comment something if you don't need its emulator

if (process.env.NODE_ENV === "development") {
    console.warn("Using Firebase Functions emulator on http://127.0.0.1/5001")
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

if (process.env.NODE_ENV === "development") {
    console.warn("Using Firebase Firestore emulator on http://127.0.0.1/8080")
    connectFirestoreEmulator(firestoreDb, "127.0.0.1", 8080);
}

if (process.env.NODE_ENV === "development") {
    console.warn("Using Firebase Auth emulator on http://127.0.0.1/9099")
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

// if (process.env.NODE_ENV === "development") {
//     console.warn("Using Firebase Storage emulator on http://127.0.0.1/9199")
//     connectStorageEmulator(storage, "127.0.0.1", 9199);
// }

export default Firebase
