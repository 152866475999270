import React, { useState, useEffect } from 'react';
import { showToast } from '../utils/utilities';

const ConnectivityWrapper = ({ children }: any) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    useEffect(() => {
        const updateConnectionStatus = () => {
            //@ts-expect-error
          const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    
          if (connection) {
            const effectiveType = connection.effectiveType || 'unknown';
            // console.log(effectiveType);

            if(effectiveType === 'slow-2g' || effectiveType === '2g'){
                showToast("Your internet is a bit slow. If things don't happen as expected, try refreshing the page", "error", 5000, "top-right")
            }
          }
        };
    
        updateConnectionStatus();
        

      }, [isOnline]);

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    useEffect(() => {
        if(!isOnline) {
            showToast("You're not connected to the internet. Please check your connectivity.", "error", 5000, "top-right")
        }

    }, [isOnline])

    return (
        <>
            {children}
        </>
    )

}

export default ConnectivityWrapper;