import axios, { AxiosInstance } from 'axios';
import { useState, useEffect } from 'react';
import { showToast } from '../utils/utilities';
import { useSelector } from 'react-redux';
import { shopSelector } from '../store/models/shopModel';
import moment from 'moment';

interface RevenueMetrics {
  daily: {
    realized: number;
    expected: number;
  };
  monthly: {
    realized: number;
    expected: number;
  };
}

interface DashboardData {
  upcomingAppointmentsCount: number;
  completedAppointmentsCount: number;
  cancelledAppointmentsCount: number;
  noShowAppointmentsCount: number;
  revenueHistory: {
    date: string;
    realized: number;
    expected: number;
  }[];
  revenue: RevenueMetrics;

  // todayAppointments: {
  //   id: string;
  //   title: string;
  //   time: string;
  //   status: 'confirmed' | 'pending' | 'canceled';
  // }[];
  // appointmentStatus: {
  //   confirmed: number;
  //   pending: number;
  //   canceled: number;
  // };
  // busyVsFreeTime: {
  //   busy: number;
  //   free: number;
  // };
  // popularTimeSlots: { [key: string]: number };
  // clientDistribution: { [key: string]: number };
}

export function useDashboardData({ startDate, endDate }: { startDate: string, endDate: string }) {
  const [data, setData] = useState<DashboardData | null>({
    upcomingAppointmentsCount: 0,
    completedAppointmentsCount: 0,
    cancelledAppointmentsCount: 0,
    noShowAppointmentsCount: 0,
    revenueHistory: [
      {
        date: "2024-12-11",
        expected: 24,
        realized: 12
      },
      {
        date: "2024-12-12",
        expected: 240,
        realized: 120
      },],
    revenue: {
      daily: {
        realized: 0,
        expected: 0
      },
      monthly: {
        realized: 0,
        expected: 0
      },
    }
  });
  const [loading, setLoading] = useState(true);
  const { selectedShop, shopsData } = useSelector(shopSelector);

  useEffect(() => {
    console.log("Fetching for data from " + startDate + " to " + endDate);
  }, [startDate, endDate]);

  let client: AxiosInstance;

  if (process.env.NODE_ENV === "development") {
    client = axios.create({
      baseURL: "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
    })
  } else {
    client = axios.create({
      baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
    })
  }

  useEffect(() => {
    // Simulating an API call
    setLoading(true);
    client.post("/fetchBusinessKPIs", {
      "businessID": shopsData[selectedShop].id,
      startDate: startDate ? startDate : new Date(),
      endDate: endDate ? endDate : new Date(),
    }).then((response) => {
      console.log(response.data);

      const today = new Date().toISOString().split("T")[0]; // "2024-12-10"
      const currentMonth = today.slice(0, 7); // "2024-12"

      // Initialize output structure
      const revenue = {
        daily: {
          realized: 0,
          expected: 0
        },
        monthly: {
          realized: 0,
          expected: 0
        },
      };

      response.data.revenueHistory.forEach((entry: any) => {
        const entryMonth = entry.date.slice(0, 7); // Extract the month

        // Check for daily aggregation
        if (entry.date === today) {
          revenue.daily.realized += entry.realized;
          revenue.daily.expected += (entry.expected + entry.realized);
        }

        // Check for monthly aggregation
        if (entryMonth === currentMonth) {
          revenue.monthly.realized += entry.realized;
          revenue.monthly.expected += (entry.expected + entry.realized);
        }
      });

      setData({
        upcomingAppointmentsCount: response.data.upcomingAppointmentsCount,
        completedAppointmentsCount: response.data.completedAppointmentsCount,
        cancelledAppointmentsCount: response.data.cancelledAppointmentsCount,
        noShowAppointmentsCount: response.data.noShowAppointmentsCount,
        revenueHistory: response.data.revenueHistory,
        revenue,
      })

      setLoading(false);
    }).catch((e) => {
      console.log("Couldn't fetch dashboard,.", e)
      setLoading(false);
      showToast("Couldn't fetch insights, please reload.", "error", 7000)
    });
    // setTimeout(() => {
    //   setData({
    //     upcomingAppointmentsCount: 15,
    //     revenue: {
    //       daily: {
    //         realized: 432.5,
    //         expected: 750,
    //       },
    //       monthly: {
    //         realized: 6246.45,
    //         expected: 9340,
    //       },
    //     },
    //     revenueHistory: [
    //       { date: '2024-12-01', realized: 400, expected: 500 },
    //       { date: '2024-12-02', realized: 450, expected: 600 },
    //       { date: '2024-12-03', realized: 500, expected: 700 },
    //       { date: '2024-12-04', realized: 550, expected: 750 },
    //       { date: '2024-12-05', realized: 500, expected: 750 },
    //     ],




    //     //these ones are for later
    //     // todayAppointments: [
    //     //   { id: '1', title: 'Project Review', time: '3:00 PM', status: 'confirmed' },
    //     //   { id: '2', title: 'Client Call', time: '4:30 PM', status: 'confirmed' },
    //     //   { id: '3', title: 'Team Sync', time: '5:00 PM', status: 'pending' },
    //     // ],
    //     // appointmentStatus: {
    //     //   confirmed: 12,
    //     //   pending: 5,
    //     //   canceled: 2,
    //     // },
    //     // busyVsFreeTime: {
    //     //   busy: 70,
    //     //   free: 30,
    //     // },
    //     // popularTimeSlots: {
    //     //   '9:00 AM': 10,
    //     //   '2:00 PM': 8,
    //     //   '11:00 AM': 6,
    //     //   '4:00 PM': 5,
    //     //   '10:00 AM': 4,
    //     // },
    //     // clientDistribution: {
    //     //   'Regular Clients': 60,
    //     //   'New Clients': 30,
    //     //   'VIP Clients': 10,
    //     // },

    //   });
    //   setLoading(false);
    // }, 1000);
  }, [startDate, endDate]);

  return { data, loading };
}

