import { createModel } from '@rematch/core'
import { RootState } from '../store'
import { RootModel } from './index'



export const Dashboard = createModel<RootModel>()({
    state: {
        startDate: "",
        endDate: ""
    } as {
        startDate: string,
        endDate: string
    },
    // state: {} as any,
    reducers: {
        setDashboardDateRange(state, payload) {
            return {
                ...state,
                startDate: payload.startDate,
                endDate: payload.endDate
            }
        },
    },
    effects: (dispatch) => ({


    }),
})

export const dashboardSelector = (state: RootState) => state.Dashboard
