import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ContentPage from '../../components/common/ContentPage'
import Section from '../../components/common/Section'
import PlanCard from '../../components/PlanCard'
import { useIsMobile } from '../../hooks/useIsMobile'
import { userSelector } from '../../store/models/userModel'
import { analyticsEvents } from '../../utils/Events'
import currencies from "../../utils/currencies.json"
import Select from 'react-select'
import TextInput from '../../components/input/TextInput'
import { fetchBusinessByID } from '../../api/booking'
import { shopSelector } from '../../store/models/shopModel'
import { updateProfessionalProfile, updateShopProfile } from '../../utils/ShopProfileTasks'
import { showToast } from '../../utils/utilities'
import PhoneInput from 'react-phone-input-2'

function PreferencesPage() {

  const { isMobile } = useIsMobile()
  const { subscription, email, name, id, phone } = useSelector(userSelector);





  const [loading, setLoading] = useState(false)

  const [upgradeLink, setUpgradeLink] = useState("")


  const [currencyList, setCurrencyList] = useState<any>([])
  const [selectedCurrency, setSelectedCurrency] = useState<any>()
  useEffect(() => {
    const currencyArray = Object.keys(currencies).map((currency: string) => {
      // @ts-ignore
      const symbol = currencies[currency as any].symbol
      // @ts-ignore
      const name = currencies[currency as any].name
      return {
        label: `${name} (${symbol})`,
        value: symbol
      }
    })
    setCurrencyList(currencyArray)
  }, [])

  const nameRef = useRef<any>()
  const phoneRef = useRef<any>()
  const emailRef = useRef<any>()
  const [phoneState, setPhoneState] = useState<string>("")

  const [notifications, setNotifications] = useState({
    sendEmailOwner: false,
    sendEmailProfessional: false,
    sendSMSProfessional: false
  })

  // const [loading, setLoading] = useState<boolean>(false)

  const save = async () => {
    try {
      setLoading(true)

      await updateShopProfile({ queryKey: ["shopID", selectedShop] }, {
        currency: selectedCurrency.value,
        notificationSettings: notifications
      })


      setLoading(false)
      showToast("Your preferences have been saved", "success")
    } catch (e) {
      console.log("Couldn't save prefs. Error: ", e)
      showToast("We couldn't save your preferences, check your internet and try again", "error", 7000)
      setLoading(false)
    }
  }

  const { selectedShop } = useSelector(shopSelector)

  const notifRef1 = useRef<any>()
  const notifRef2 = useRef<any>()
  const notifRef3 = useRef<any>()

  function getCurrencyInfoByValue(valueToFind: string) {
    for (const key in currencies) {
      // @ts-ignore
      if (currencies.hasOwnProperty(key) && currencies[key].symbol === valueToFind) {
        // @ts-ignore
        const { name, symbol } = currencies[key];
        return { label: `${name} (${symbol})`, value: symbol };
      }
    }
    // Return a default value or handle not found case
    return { label: "Currency not found", value: "" };
  }

  useEffect(() => {
    nameRef.current?.setValue(name)
    phoneRef.current?.setValue(phone)
    emailRef.current?.setValue(email)

    async function fetchCurrentShopData() {
      await fetchBusinessByID({ queryKey: ["shopID", selectedShop] })
        .then((data) => {
      
          setNotifications(data?.notificationSettings)
          setSelectedCurrency(getCurrencyInfoByValue(data?.currency))
          // setIsLive({
          //   label: data?.isLive ? "Active" : "Inactive",
          //   value: data?.isLive ? true : false
          // })
        })
    }
    fetchCurrentShopData()
  }, [selectedShop])


  return (
    <ContentPage title="Edit preferences" subtitle='' actionText='Save' loadingAction={loading} showActionButton onClickAction={save}>
      <div className='sm:px-6 sm:pt-6 p-4'>
        <div className='w-full'>

          {/* <Section title='Admin' subtitle="Edit the admin's data">
            <div className="space-y-2 w-full max-w-sm">
              <TextInput
                ref={nameRef}
                placeholder='Enter your name'
                showLabel
                label="Name"

              />
              <TextInput
                ref={phoneRef}
                placeholder='Enter your phone number'
                showLabel
                label="Phone (including country code)"

              />

              <TextInput
                disabled
                ref={emailRef}
                placeholder='example@something.com'
                showLabel
                label="Email address"
                type={"email"}
              />
              <p className="text-sm text-gray-500">If you need to change the admin's email, please contact us</p>
            </div>
          </Section> */}
          <Section title='Currency' subtitle="Select the currency for the prices of this business' services">
            <div className="max-w-sm space-y-2">
              <Select options={currencyList} onChange={(change) => {
                setSelectedCurrency(change)
                
              }}
                value={selectedCurrency}
              />
              <p className="text-sm text-gray-500">Changing the currency will not automatically adjust the prices of booked appointments. Inform your customers of any change that can affect their appointments.</p>
            </div>
          </Section>
          <Section title='Notifications' subtitle="Manage how and to whom notifications are sent. We send notifications for appointment events: booked, cancelled, accepted, rejected">
            <div className="space-y-2">

              <div>
                <span className='mr-2'>
                  <input type="checkbox" checked disabled />
                </span>
                Send an email to the customer
                <div className="text-sm text-gray-500">
                  For an optimal user experience, we always send an email to your customers when they book
                </div>
              </div>

              <div>
                <span className='mr-2'>
                  <input checked={notifications.sendEmailOwner} type="checkbox" onChange={(change) => {
                    setNotifications({
                      ...notifications,
                      sendEmailOwner: change.target.checked,
                    }
                    )
                  }} />
                </span>
                Send an email to the business email address
              </div>

              <div>
                <span className='mr-2'>
                  <input checked={notifications.sendEmailProfessional} type="checkbox" onChange={(change) => {
                    setNotifications({
                      ...notifications,
                      sendEmailProfessional: change.target.checked,
                    }
                    )
                  }} />
                </span>
                Send an email to the professional
              </div>

              <div>
                <span className='mr-2'>
                  <input checked={notifications.sendSMSProfessional} type="checkbox" onChange={(change) => {
                    setNotifications({
                      ...notifications,
                      sendSMSProfessional: change.target.checked
                    }
                    )
                  }} />
                </span>
                Send an SMS to the professional
              </div>
            </div>
          </Section>


        </div>
      </div>
    </ContentPage>
  )
}

export default PreferencesPage