import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import { models, RootModel } from './models'
import storage from 'redux-persist/lib/storage'
import persistPlugin from '@rematch/persist'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['User', 'Dashboard'],
}

export const store = init<RootModel>({
    models,
    plugins: [persistPlugin(persistConfig)],

    redux: {
        devtoolOptions: {
          actionSanitizer: (action) => action,
          trace: true,
        },
      },
})

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>
