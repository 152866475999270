import { useDashboardData } from "../hooks/useDashboardData"
import { Card, Title, Text } from "@tremor/react"
import { Grid, Col } from "@tremor/react"
import { Metric, Flex, ProgressBar } from "@tremor/react"
import { useDispatch, useSelector } from "react-redux"
import { shopSelector } from "../store/models/shopModel"
import { serviceSelector } from "../store/models/Services"
import { professionalSelector } from "../store/models/professionalsModel"
import { useEffect, useState } from "react"
import RevenueHistoryChart from "./RevenueHistoryChart"
import { Oval } from "react-loader-spinner"
import AppointmentCategoryChart from "./Donut"
import moment from "moment"
import { DateRange, DateRangePicker } from "./tremor/DatePicker"
import { Divider } from "./tremor/Divider"
import { DatePrickerPresets } from "./tremor/DatePickerUtils"
import { dashboardSelector } from "../store/models/dashboardModel"

export default function DashboardInsights() {

  const dispatch = useDispatch()
  const shop = useSelector(shopSelector)
  const services = useSelector(serviceSelector)
  const pros = useSelector(professionalSelector)
  const dash = useSelector(dashboardSelector);

  const [servicesCount, setServicesCount] = useState<number>(0)
  const [prosCount, setprosCount] = useState<number>(0)

  useEffect(() => {
    setServicesCount(Object.keys(services)?.length)
    setprosCount(pros.proIDs[shop.selectedShop]?.length)
  }, [services, pros, shop.selectedShop, servicesCount, prosCount])

  // sets the initial date as the last selected date range
  // if no last selected date range is available, picks today's date
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: dash.startDate ? new Date(dash.startDate) : new Date(),
    to: dash.endDate ? new Date(dash.endDate) : new Date(),
  });

  // update the date range in the state as soon as it changes
  useEffect(() => {
    dispatch.Dashboard.setDashboardDateRange({
      startDate: moment(new Date((dateRange?.from)?.toString() ? (dateRange?.from)?.toString() : "")).format("YYYY-MM-DD"),
      endDate: moment(new Date((dateRange?.to)?.toString() ? (dateRange?.to)?.toString() : "")).format("YYYY-MM-DD")
    })
  }, [dateRange])

  const { data, loading } = useDashboardData({
    startDate: moment(new Date((dateRange?.from)?.toString() ? (dateRange?.from)?.toString() : "")).format("YYYY-MM-DD"),
    endDate: moment(new Date((dateRange?.to)?.toString() ? (dateRange?.to)?.toString() : "")).format("YYYY-MM-DD")
  });


  const appointmentDistribution = [
    {
      name: 'Upcoming',
      count: data?.upcomingAppointmentsCount ?? 0,
      color: 'bg-cyan-500 dark:bg-cyan-500',
    },
    {
      name: 'Completed',
      count: data?.completedAppointmentsCount ?? 0,
      color: 'bg-green-500 dark:bg-green-500',
    },
    {
      name: 'Cancelled',
      count: data?.cancelledAppointmentsCount ?? 0,
      color: 'bg-red-500 dark:bg-red-500',
    },
    {
      name: 'No show',
      count: data?.noShowAppointmentsCount ?? 0,
      color: 'bg-yellow-500 dark:bg-yellow-500',
    },
  ]

  if (loading) {
    return <div className="flex items-center justify-center h-screen">
      <Oval color='#ffffff' secondaryColor='#7D7D7D' width={24} />
      <p>Loading...</p>
    </div>;
  }

  if (!data) {
    return <div className="flex items-center justify-center h-screen">Error loading dashboard data</div>;
  }


  return (
    <div className="p-6  mx-auto_ max-w-7xl_ rounded-lg bg-white shadow">

      <Divider />

      <div className="w-full flex justify-end">
        <DateRangePicker
          enableYearNavigation
          value={dateRange}
          presets={DatePrickerPresets}
          onChange={setDateRange}
          className="w-60"
        />
      </div>
      <Grid numItemsSm={2} numItemsLg={3} className="gap-8 mt-6">


        <Card decoration="top" decorationColor="blue" className=" border rounded-lg">
          <Title className="text-gray-600">Upcoming Appointments</Title>
          <Metric className="text-4xl">{data.upcomingAppointmentsCount}</Metric>
        </Card>


        <Card decoration="top" decorationColor="green" className=" border rounded-lg">
          <Title className="text-gray-600">Revenue over selected period</Title>
          <Metric className="text-4xl">{shop.shopsData[shop.selectedShop]?.currency}{data?.revenueHistory.reduce((acc: any, rev: any) => acc + parseFloat(rev.realized), 0)}</Metric>
          <Flex className="mt-4">
            <Text>Expected</Text>
            <Text>{shop.shopsData[shop.selectedShop]?.currency}{data?.revenueHistory.reduce((acc: any, rev: any) => acc + parseFloat(rev.expected), 0)}</Text>
          </Flex>
          <ProgressBar value={data.revenue.daily.realized / data.revenue.daily.expected * 100} className="mt-2 rounded-full" />
        </Card>

      </Grid>

      <Grid className="gap-6 mt-6">
        <Col numColSpan={1} numColSpanLg={2}>
          <Card className=" border rounded-lg">
            <Title className="text-gray-600">Revenue History</Title>

            <RevenueHistoryChart data={data.revenueHistory ? data.revenueHistory : [
              {
                date: moment(new Date()).format("YYYY-MM-DD"),
                expected: 0,
                realized: 0
              }
            ]} />
          </Card>
        </Col>

      </Grid>
      <Grid className="gap-6 my-6">
        <AppointmentCategoryChart data={appointmentDistribution} />
      </Grid>
    </div>
  )
}

