import { doc, getDoc, updateDoc } from "firebase/firestore"
import { firestoreDb } from "../config/firebase.config"

/**
 * Updates the shop profile in Firebase
 * @param query the query containing the shop ID you wish to update which is of the form { queryKey: ["your_id", the_query_key] }
 * @param updateData the data you wish to update
 */
export async function updateShopProfile({
    queryKey,
}: {
    queryKey: string[]
}, updateData: any) {
    try {
        const [_, shopID] = queryKey

    const shopRef = doc(firestoreDb, "Businesses", shopID)
    await updateDoc(shopRef, updateData)
    } catch(e) {
        console.log("Couldn't update shop data.", e)
    }

}

/**
 * Updates the professional's profile in Firebase
 * @param query the query containing the pro ID you wish to update which is of the form { queryKey: ["your_id", the_query_key] }
 * @param updateData the data you wish to update
 */
export async function updateProfessionalProfile({
    queryKey,
}: {
    queryKey: string[]
}, updateData: any) {
    try {
        const [_, proID] = queryKey

    const proRef = doc(firestoreDb, "Users", proID)
    await updateDoc(proRef, updateData)
    } catch(e) {
        console.log("Couldn't update pro data.", e)
    }

}