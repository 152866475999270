import { QueryClient, QueryClientProvider } from 'react-query'
import { RouterProvider } from 'react-router-dom'
import { createAppRoutes } from './routes'
import { useSelector } from 'react-redux'
import { userSelector } from './store/models/userModel'
import ReactGA from "react-ga4";
import { shopSelector } from './store/models/shopModel'


const queryClient = new QueryClient()

ReactGA.initialize("G-RJN482XSMR")



function App() {
    const userState = useSelector(userSelector)
    const business = useSelector(shopSelector)
    const router = createAppRoutes({
        isLoggedIn: userState?.id,
        isOnboarded: Object.keys(business.shopsData).length > 0
    })

    return (
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
    )
}

export default App
