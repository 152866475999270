import React, { useEffect } from 'react'
import * as Icon from "react-feather"

type TableHeaderProps = {
    sortingOder?: "asc" | "desc" | any,
    showSorting?: boolean,
    children: any,
    onHeaderClick?: () => void | any,
}
function TableHeader({ children, sortingOder, onHeaderClick, showSorting }: TableHeaderProps) {
    useEffect(() => {
        // console.log(sortingOder)


    }, [sortingOder])

    return (
        <div onClick={onHeaderClick} className='flex justify-between items-center cursor-pointer bg-gray-100 text-left p-4 text-xs font-medium text-gray-500 m-0'>
            <div>
                {children}
            </div>
            {sortingOder && showSorting ?
                <div>

                    {sortingOder === "asc" &&
                        <div>
                            <Icon.ArrowUp size={16} />
                        </div>
                    }
                    {sortingOder === "desc" &&
                        <div>
                            <Icon.ArrowDown size={16} />
                        </div>
                    }

                </div> :
                <div>
                    <div>
                        {showSorting && <Icon.Menu color='#98a2b3' size={12} />}
                    </div>

                </div>
                }
        </div>
    )
}

export default TableHeader