import axios from 'axios'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../components/button'
import ContentPage from '../../components/common/ContentPage'
import Section from '../../components/common/Section'
import TextInput from '../../components/input/TextInput'
import { userSelector } from '../../store/models/userModel'
import { showToast } from '../../utils/utilities'

function Help() {

    const [loading, setLoading] = useState(false)

    const messageRef = useRef<any>()
    const user = useSelector(userSelector)
    return (
        <div className='h-full flex-1 p-4'>
            <ContentPage title={'Help'} subtitle='Need help with something? Let us know' actionText={''}>
                <div className='sm:px-6 sm:pt-6 p-4'>
                    <Section title={'Send us a message'} subtitle="Do you have a particular problem or a feature you'd like us to add? Send us a message with the details">
                        <div className='flex-1 max-w-2xl'>
                            <div>
                            Get the help you need through our Help Desk Portal, packed with FAQs and informative articles. Our support team is also available to assist you. For quick queries, feel free to use the support widget in the bottom right corner.
                            </div>
                            
                            <div className='mt-4'>
                               

                                <a target="_blank" href='https://wavier.freshdesk.com/support/home' rel="noreferrer">

                                    <Button disabled={false} loading={loading} onClick={() => {

                                    }} hug size={'sm'} >
                                        Go to the Helpdesk Portal
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </Section>
                </div>
            </ContentPage>
        </div>
    )
}

export default Help