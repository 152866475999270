import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { RootState } from "../store";

interface IAppointmentModel {
    upcoming: any,
    history: any,
    loadingAppointments: boolean,
    proAppointmentLoading: boolean
}

export const AppointmentModel = createModel<RootModel>()({
    state: {
        upcoming: [],
        history: [],
        loadingAppointments: true,
        proAppointmentLoading: true
    } as IAppointmentModel,
    reducers: {
        saveUpcoming: (state: any, payload)  => {
            // console.log("Saving upcoming appointments in state")
            return {
                ...state,
                upcoming: payload
            }
        },
        saveHistory: (state: any, payload)  => {
            // console.log("Saving history appointments in state")
            return {
                ...state,
                history: payload
            }
        },
        setLoadingAppointments: (state: any, payload)  => {
            // console.log("Setting loading appointments to", payload)
            return {
                ...state,
                loadingAppointments: payload
            }
        },
        setProAppointmentLoading: (state: any, payload)  => {
            // console.log("Setting loading of pro appointment appointments to", payload)
            return {
                ...state,
                proAppointmentLoading: payload
            }
        },
    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        // async incrementAsync(payload, rootState) {
        //   await new Promise((resolve) => setTimeout(resolve, 1000));
        //   dispatch.count.increment(payload);
        // },
    }),
})

export const AppointmentSelector = (state: RootState) => state.AppointmentModel