export const canAddService = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canAddUser = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canRemoveUser = (userRoles: string[]) => {
    const roles = ['owner', 'admin'];
    return checkPermission(userRoles, roles);
};

export const canRemoveOwner = (userRoles: string[]) => {
    const roles = ['owner'];
    return checkPermission(userRoles, roles);
};

export const canSetNewOwner = (userRoles: string[]) => {
    const roles = ['owner']
    return checkPermission(userRoles, roles)
}

export const canPromoteUser = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canDemoteUser = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canAddPayment = (userRoles: string[]) => {
    const roles = ['owner']
    return checkPermission(userRoles, roles)
}

export const canUpdatePayments = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canRemovePayments = (userRoles: string[]) => {
    const roles = ['owner']
    return checkPermission(userRoles, roles)
}

export const canSeeAllAppointments = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canAcceptAllAppointments = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canRejectAllAppointments = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canCheckoutAllAppointments = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canViewAppointments = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canSeeAllCustomers = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canReachOutToCustomers = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canReceiveAppointments = (userRoles: string[]) => {
    const roles = ['owner', 'admin', 'staff']
    return checkPermission(userRoles, roles)
}

export const canEditService = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canRemoveService = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canAddCategory = (userRoles: string[]) => {
    const roles = ['owner', 'admin'];
    return checkPermission(userRoles, roles);
  };
  export const canEditCategory = (userRoles: string[]) => {
    const roles = ['owner', 'admin'];
    return checkPermission(userRoles, roles);
  };
  export const canRemoveCategory = (userRoles: string[]) => {
    const roles = ['owner', 'admin'];
    return checkPermission(userRoles, roles);
  };

export const canEditBusinessProfile = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canEditBusinessWorkingHours = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canManageSubscription = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

// export const canEditCurrency = (userRoles: string[]) => {
//     const roles = ['owner', 'admin']
//     return checkPermission(userRoles, roles)
// }

export const canEditPreferences = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canEditPolicies = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const canSeeBookingLink = (userRoles: string[]) => {
    const roles = ['owner', 'admin', 'staff']
    return checkPermission(userRoles, roles)
}

export const canAddNewBranch = (userRoles: string[]) => {
    const roles = ['owner']
    return checkPermission(userRoles, roles)
}

export const canDeleteBusiness = (userRoles: string[]) => {
    const roles = ['owner']
    return checkPermission(userRoles, roles)
}

export const canAssignServiceToStaff = (userRoles: string[]) => {
    const roles = ['owner', 'admin']
    return checkPermission(userRoles, roles)
}

export const checkPermission = (userRoles: string[], requiredRoles: string[]) => {
    if (userRoles) {
        const isPermitted = requiredRoles.some((role) => {
            return userRoles.includes(role)
        })

        return isPermitted
    } else {
        return undefined
    }
}