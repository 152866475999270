import { collection, doc, DocumentData, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";

import { fetchUserByID, fetchBusinessByID, fetchUserFromBookings, fetchBusinessSettings } from "../api/booking";

import { userSelector } from "../store/models/userModel";
import { useDispatch, useSelector } from "react-redux";
import { firestoreDb } from '../config/firebase.config'
import { shopSelector } from "../store/models/shopModel";
import { professionalSelector } from "../store/models/professionalsModel";
import { fetchCategories, fetchUsers } from "../api/api";
import { canSeeAllAppointments } from "../utils/roles";
import { modalSelector } from "../store/models/modals";
import moment from "moment";


// export const DatabaseStreamContext = createContext<any>(null)

const DatabaseStream = ({ children }: any) => {

    const dispatch = useDispatch()

    const { selectedShop, shopsData } = useSelector(shopSelector)
    const { onboardingModal } = useSelector(modalSelector)

    // const [pros, setPros] = useState<any>();

    const { selectedPro } = useSelector(professionalSelector)


    const [services, setServices] = useState<any>({})
    const [staff, setStaff] = useState<any>({})
    const user = useSelector(userSelector);

    const [IDs, setIDs] = useState({});

    
    useEffect(() => {
        if (shopsData[selectedShop]) {
            if (moment(Date.now()).isAfter(shopsData[selectedShop].settings?.subscription?.trialEnd) && shopsData[selectedShop].settings?.subscription?.billingState === "inactive") {
            dispatch.Modals.toggleUpgradeModal(true)
            console.log("upgrade");
            }
        }
    }, [shopsData, selectedShop]);

    useEffect(() => {
        if (shopsData[selectedShop]) {
            if (!shopsData[selectedShop].settings?.onboarding.activated) {
                dispatch.Modals.toggleOnboardingModal(true)
                console.log("onboarding");
            }
        }
    }, [shopsData, selectedShop]);


    /* -------------------------------------------------------------------------- */
    /*                               fetch user data                              */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {

        var userData: any = {}
        const unsubUserStream = onSnapshot(doc(firestoreDb, `Users/${user?.id}`), async (shopDocument) => {
            userData = shopDocument.data()

            dispatch.User.saveUser(userData)
        })

        return () => {
            unsubUserStream()
        }
        // }

    }, [dispatch.User, user?.id])


    /* -------------------------------------------------------------------------- */
    /*                               Get user roles                               */
    /* -------------------------------------------------------------------------- */

    const [allUsers, setAllUsers] = useState<any>(null);
    useEffect(() => {
        if (selectedShop) {

            const q = doc(firestoreDb, "Businesses", selectedShop, "Users", user.id);

            const unsubRolesStream = onSnapshot(q, async (roleDoc) => {
                const roles = roleDoc.data();
                dispatch.User.setRoles(roles?.roles)
            })



            const qUsers = collection(firestoreDb, "Businesses", selectedShop, "Users");
            const unsubUsersStream = onSnapshot(qUsers, async (users) => {
                const allUsersTemp: any[] = [];
                users.forEach((user) => {
                    allUsersTemp.push(user.data());
                });
                setAllUsers(allUsersTemp);

            })

            return () => {
                unsubRolesStream()
                unsubUsersStream()
            }
        }

    }, [selectedShop, user.id]);

    /* -------------------------------------------------------------------------- */
    /*         // fetch the shop's data for the currently logged in owner         */
    /* -------------------------------------------------------------------------- */
    // this just sets the first shop as default once the app starts
    //the following use effect was improved using COntinue to change all async calls into promises
    // which ae awaited using Promise.all

    useEffect(() => {
        try {
            let userBusinesses = user?.businesses; //all the businesses the user is part of

            // Map over user's businesses and create a promise for each
            const businessPromises = userBusinesses?.map(async (businessID: string) => {
                const data = await fetchUsers(businessID);

                // Create a promise for each user
                const userPromises = data.map(async (userObj: any) => {
                    const fetchedUser = await fetchUserByID({ queryKey: ["user", userObj.id] });
                    // const fetchedUserRoles = await fetchUserRoles(businessID, userObj.id);

                    const userRoles = allUsers?.filter((user: any) => {
                        return user.id === userObj.id;
                    })[0];

                    // console.log("fetchedUserRoles", fetchedUserRoles);
                    // console.log("userRoles", userRoles);

                    // Check if user has "staff" role
                    let isStaff = userRoles?.roles.includes("staff");

                    return {
                        id: userObj.id,
                        user: fetchedUser,
                        roles: userRoles?.roles,
                        isStaff
                    };
                });

                // Wait for all user promises to resolve
                const users = await Promise.all(userPromises);

                // Populate the global users data
                const usersData = users.reduce((acc, curr) => {
                    acc[curr.id] = {
                        ...curr.user,
                        "roles": curr.roles,
                    };
                    return acc;
                }, {});

                // Extract IDs for staff only
                const proIDsArray = users.filter(user => user.isStaff).map(user => user.id);
                const proNamesArray = users.filter(user => user.isStaff).map(user => user.user.name);

                // Populate the business data
                const businessData = await fetchBusinessByID({ queryKey: ["id", businessID] });

                const settings = await fetchBusinessSettings({ queryKey: ["id", businessID] });

                return {
                    businessID,
                    businessData,
                    settings,
                    usersData,
                    proIDsArray,
                    proNamesArray
                };
            });

            // Wait for all business promises to resolve
            Promise.all(businessPromises).then(businesses => {
                const shopsData = {};
                const usersData = {};
                const proIDs = {};
                const proNames = {};

                businesses.forEach(business => {
                    //@ts-ignore
                    shopsData[business.businessID as any] = {
                        ...business.businessData,
                        settings: business.settings,
                    };
                    //@ts-ignore
                    usersData[business.businessID as any] = business.usersData;
                    //@ts-ignore
                    proIDs[business.businessID as any] = business.proIDsArray;
                    //@ts-ignore
                    proNames[business.businessID as any] = business.proNamesArray;
                });

                if (!selectedShop || selectedShop.length === 0) {
                    dispatch.Shop.setSelectedShop(user?.businesses[0]);
                }
                dispatch.Shop.saveShops(shopsData);
                dispatch.Professionals.setUsersData(usersData);
                dispatch.Professionals.setProNames(proNames);
                setIDs(proIDs);
                setStaff(proIDs);


            });
        } catch (e) {
            console.log("Couldn't fetch business data in wrapper. Error:", e);
        }
    }, [user?.businesses, user.id, allUsers]);


    useEffect(() => {
        if (staff) {
            const staffInBusiness = staff[selectedShop];

            if (staffInBusiness) {

                //if the user has the right to see all appointment, choose the first ID in the list as defaukt
                if (canSeeAllAppointments(user.roles)) {
                    dispatch.Professionals.setSelectedPro(staffInBusiness[0]);
                } else {
                    // if they don't have the permission, choose their ID
                    dispatch.Professionals.setSelectedPro(user.id);
                }
            }

        }
    }, [staff, selectedShop])

    useEffect(() => {

        dispatch.Professionals.setProIDs(IDs);
    }, [IDs])
    /* -------------------------------------------------------------------------- */
    /*              // fetch the data of the currently selected shop              */
    /* -------------------------------------------------------------------------- */

    const fetchCategoriesData = async (businessID: string) => {
        const categories = await fetchCategories(businessID);
        return categories;
    }
    useEffect(() => {
        if (selectedShop) {
            var shopData: any = {}

            const unsubShopStream = onSnapshot(doc(firestoreDb, `Businesses/${selectedShop}`), async (shopDocument) => {
                shopData = shopDocument.data()

                setStaff(staff)
                dispatch.Shop.setCurrency({
                    businessID: selectedShop,
                    currency: shopData.currency,
                });
            })

            const unsubSettingsStream = onSnapshot(collection(firestoreDb, `Businesses/${selectedShop}/Settings`), async (settingsDoc) => {
                let settings = {};
                settingsDoc.forEach((doc) => {
                    settings = {
                        ...settings,
                        [doc.id]: doc.data(),
                    }


                });

                dispatch.Shop.saveSettings({
                    businessID: selectedShop,
                    settings,
                });
                // console.log(settings)
            })

            return () => {
                unsubShopStream()
                unsubSettingsStream()
            }
        }

    }, [dispatch.Services, selectedShop, staff, user?.id])


    useEffect(() => {
        if (selectedShop) {

            const qCategory = query(collection(firestoreDb, "Businesses", selectedShop, "Categories"));
            let categories: string[] = [];
            const unsubCategoriesStream = onSnapshot(qCategory, async (categoryDocuments) => {
                const categoriesArray: any[] = []

                categoryDocuments.forEach((doc) => {

                    categoriesArray.push(doc.data());

                })
                categories = categoriesArray;
                dispatch.Shop.saveCategories(categoriesArray.map((category: any) => category));

            });


            const q = query(collection(firestoreDb, "Businesses", selectedShop, "Services"))
            const unsubServicesStream = onSnapshot(q, async (serviceDocument) => {
                const services: any[] = []
                let allServices: any = {}
                let uncategorizedServices: any[] = []

                serviceDocument.forEach((doc) => {
                    services.push(doc.data());
                })

                uncategorizedServices = services;

                if (categories.length > 0) {
                    categories.map((category: any) => {

                        // filter out services in the category
                        const servicesInCategory = services.filter((service: any) => {
                            return service.category === category.id
                        });

                        //pop out services from the uncategorized list
                        servicesInCategory.map((service: any, index: number) => {
                            uncategorizedServices.splice(uncategorizedServices.indexOf(service), 1);
                        });

                        allServices = {
                            ...allServices,
                            [category.name]: servicesInCategory,
                            "Uncategorized": uncategorizedServices,
                        }
                    })
                } else {
                    allServices = {
                        ...allServices,
                        "Uncategorized": uncategorizedServices,
                    }
                }

                setServices(allServices) // used locally
                dispatch.Services.saveServices(allServices ? allServices : {}) //used everywhere else

            })

            const qInvites = query(collection(firestoreDb, "Businesses", selectedShop, "Invites"))
            const unsubInvitesStream = onSnapshot(qInvites, async (inviteDocument) => {
                const invites: any[] = []


                inviteDocument.forEach((doc) => {
                    invites.push(doc.data());
                })

                dispatch.Shop.saveInvites(invites) //used everywhere else

            })

            return () => {
                unsubServicesStream();
                unsubCategoriesStream();
                unsubInvitesStream();
            }
        }

    }, [dispatch.Services, selectedShop, user?.id])





    /* -------------------------------------------------------------------------- */
    /*            get the appointments for each pro, real-time updates            */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {

        // create the stream from the database to view the currently selected pro aappointments
        // TODO: create a stream for each pro automatically to receive updates from all at the same time
        if (staff && selectedPro) {
            dispatch.AppointmentModel.setLoadingAppointments(true)
            dispatch.AppointmentModel.setProAppointmentLoading(true)

            const q = query(collection(firestoreDb, "Users", selectedPro, "Appointments"));
            var upcomingData: DocumentData[] = [];
            var historyData: DocumentData[] = [];
            var allAppointments: DocumentData[] = [];

            // create the stream for upcocming appointments
            const unsubFetchAppointment = onSnapshot(q, async (snapshot) => {
                upcomingData = []; //give it an empty array before populating else it'll duplicate existing data
                historyData = [];
                allAppointments = [];

                snapshot.docs?.forEach((doc) => {
                    allAppointments.push(doc.data())
                })

                // filter out upcoming appointments
                upcomingData = allAppointments.filter((appointment: any) => {
                    return appointment.status === "pending" || appointment.status === "accepted"
                })

                //anything else is history
                historyData = allAppointments.filter((appointment: any) => {
                    return appointment.status === "completed" ||
                        appointment.status === "rejected" ||
                        appointment.status === "cancelled" ||
                        appointment.status === "cancelled_pro" ||
                        appointment.status === "cancelled_pro" ||
                        appointment.status === "didntShow" ||
                        appointment.status === "unknown"
                })


                // attach most recent user data to it for upcoming
                const upcomingDataWithUsers = await Promise.all(
                    upcomingData.filter((apt: any) => {
                        //filter out appointments that are not for the selected business
                        return apt.businessID === selectedShop
                    }).map(async (item) => {

                        if (item.clientID) {
                            const foundUser = await fetchUserFromBookings({
                                queryKey: ['user_client', item.clientID],
                            })
                            if (foundUser) {
                                return { ...item, user: foundUser }
                            }
                        }
                        return item
                    })
                )

                // attach most recent user data to it for history
                const historyDataWithUsers = await Promise.all(
                    historyData.map(async (item) => {

                        if (item.clientID) {
                            const foundUser = await fetchUserFromBookings({
                                queryKey: ['user_client', item.clientID],
                            })
                            if (foundUser) {
                                return { ...item, user: foundUser }
                            }
                        }
                        return item
                    })
                )


                // dispatch the results
                dispatch.AppointmentModel.saveUpcoming(upcomingDataWithUsers ? upcomingDataWithUsers : [])
                dispatch.AppointmentModel.saveHistory(historyDataWithUsers)
                dispatch.AppointmentModel.setLoadingAppointments(false)
                dispatch.AppointmentModel.setProAppointmentLoading(false)
            })

            return () => {
                unsubFetchAppointment();
            }
        } else {
            dispatch.AppointmentModel.setLoadingAppointments(false)
            dispatch.AppointmentModel.setProAppointmentLoading(false)
        }

    }, [dispatch.AppointmentModel, staff, selectedPro, services])

    return (
        <>
            {children}
        </>
    );
}


export default DatabaseStream;