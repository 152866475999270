import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'
import { AppPageSelector } from '../../store/models/appPage'
import { canEditPolicies, canEditPreferences } from '../../utils/roles'
import { userSelector } from '../../store/models/userModel'

interface ShopPage {
  name: string,
  link: string
}





function ShopDataPage() {

  const dispatch = useDispatch()
  const { page } = useSelector(AppPageSelector)
  const { roles } = useSelector(userSelector)

  let pages: ShopPage[] = [];
  
  const firstPages = [
    {
      name: "Profile",
      link: "profile"
    },
    {
      name: "Services",
      link: "services"
    },
    {
      name: "Users",
      link: "users"
    },
    {
      name: "Subscription & Billing",
      link: "plans"
    },
  ]

  const policies = [ 
  {
    name: "Policies",
    link: "policies"
  },
  ]
 
  const prefs = [ 
  {
    name: "Preferences",
    link: "preferences"
  },
  ]

  pages = [...firstPages];

  if (canEditPolicies(roles)) {
    pages = [...pages, ...policies];
  }
 
  if (canEditPreferences(roles)) {
    pages = [...pages, ...prefs];
  }
 

  const { isMobile } = useIsMobile();

  return (
    <div className="py-2 px-4 sm:px-6 bg-gray-50 h-full flex flex-col">
      <div className="w-full pt-4 border-gray-100 flex flex-col ">
        <div >
          <p className={`mb-2 font-semibold leading-6 ${isMobile ? "text-xl" : "text-2xl"}`}>
            {"Manage business"}
          </p>
        </div>
      </div>
      <div className='flex md:mt-4 w-full flex-wrap'>
        {pages.map((pageItem, index) => {
          return (
            <div key={index} className={`transition mr-8 h-12 ${page.includes(pageItem.link) ? "border-b-2 border-gray-900" : ""} `} onClick={() => {
              dispatch.AppPage.changeAppPage(pageItem.link)
            }}>
              <Link className='flex flex-1 h-full items-center justify-center' to={pageItem.link}>
                <div className={`transition text-sm md:text-base ${page.includes(pageItem.link) ? "text-gray-900" : "text-gray-400"}`}>
                  {pageItem.name}
                </div>
              </Link>
            </div>
          )
        })}
      </div>
      <div className='my-4 p-1 flex flex-1  w-full overflow-auto'>
        <Outlet />
      </div>
    </div>
  )
}

export default ShopDataPage