import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import ArrowRightIcon from './svgs/ArrowRightIcon'
import CalendarIcon from './svgs/CalendarIcon'
import ClockCalenderIcon from './svgs/ClockCalenderIcon'


interface Props {
    text: string,
    icon?: any,
    showText?: boolean,
    showIcon?: boolean,
    onClick?: () => void
    active: boolean,
    pageName: string
}

const SideBarItem = ({ text, icon, showText, showIcon, active, onClick, pageName }: Props) => {

    const dispatch = useDispatch()

    return (
        <Link to={pageName}>
            <div
                className={`flex items-center px-4 mx-6 py-3 cursor-pointer rounded-lg  ${active ? 'bg-gray-700' : 'hover:bg-gray-800'
                    }`}
                onClick={() => {
                    dispatch.AppPage.changeAppPage(pageName)
                    dispatch.AppPage.setSideBarState(false)
                }}
            >

                <div className="flex items-center">
                    <div className="mr-5">
                        {icon}
                    </div>
                    <p
                        className={` text-sm text-white`}
                    >
                        {text}
                    </p>
                </div>

            </div>
        </Link>
    )
}

export default SideBarItem
