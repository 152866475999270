import React from 'react'
import { Button } from '../button'

interface IContentPage {
    children: string | JSX.Element | JSX.Element[]
    title: string,
    subtitle?: string,
    actionText: string,
    secondaryActionText?: string,
    showActionButton?: boolean,
    showSecondaryActionButton?: boolean,
    actionButtonActive?: boolean,
    secondaryActionButtonActive?: boolean,
    onClickAction?: () => void,
    onClickSecondaryAction?: () => void,
    loadingAction?: boolean
    loadingSecondaryAction?: boolean
}

function ContentPage({
    children,
    title,
    subtitle,
    actionText,
    secondaryActionText,
    showActionButton,
    showSecondaryActionButton,
    actionButtonActive,
    secondaryActionButtonActive,
    onClickAction,
    onClickSecondaryAction,
    loadingAction,
    loadingSecondaryAction
}: IContentPage) {
    return (
        <div className='bg-white rounded-lg ring ring-gray-200 w-full overflow-auto h-full'>
            <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center'>
                <div className='sm:px-6 pt-6 px-4'>
                    <div className='text-lg font-medium'>
                        {title}
                    </div>
                    <div className='text-md text-gray-600'>
                        {subtitle}
                    </div>
                </div>
                <div className='flex flex-row gap-2 w-full sm:w-auto justify-center items-center'>
                    <div className='pt-6'>
                        {showSecondaryActionButton && (
                            <Button size="sm" secondaryGray loading={loadingSecondaryAction} disabled={!secondaryActionButtonActive ? false : secondaryActionButtonActive} onClick={() => {
                                if (onClickSecondaryAction) {
                                    onClickSecondaryAction()
                                }
                            }}

                            
                                hug={true}
                            >
                                {secondaryActionText}
                            </Button>
                        )}
                    </div>
                    <div className='pr-6 pt-6'>
                        {showActionButton && (
                            <Button size="sm" loading={loadingAction} disabled={!actionButtonActive ? false : actionButtonActive} onClick={() => {
                                if (onClickAction) {
                                    onClickAction()
                                }
                            }}
                                hug={true}
                            >
                                {actionText}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className=''>
                {children}
            </div>
        </div>
    )
}

export default ContentPage