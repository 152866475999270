import moment from 'moment';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import * as Icon from 'react-feather';
// import AnonymousUserImage from '../../assets/images/anonymous.jpeg'

import { Oval } from 'react-loader-spinner'
import AppointmentStatusDetails from './AppointmentStatusDetails';
import Select from 'react-select';
import { useIsMobile } from '../hooks/useIsMobile';
import { Button } from './button';
import { firestoreDb } from '../config/firebase.config';
import { arrayRemove, collection, doc, updateDoc } from 'firebase/firestore';
import { showToast } from '../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { shopSelector } from '../store/models/shopModel';
import { userSelector } from '../store/models/userModel';
import { deleteShopService } from '../utils/ShopServicesTasks';

interface IProfessionalDetails {
    service: any,
    shop?: any
    onClose?: () => void,
    onCancel?: () => void,
    onDelete?: () => void,
    loadingDetails: boolean
    // onDidntShow: () => void,
    // onAccept: () => void,
    // onReject: () => void,
    // onActionError: () => void
}

function DeleteService({ service, onClose, shop, loadingDetails, onCancel, onDelete }: IProfessionalDetails) {

    const { isMobile } = useIsMobile()
    const { selectedShop, shopsData } = useSelector(shopSelector)
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false)

    const [defaultCategories, setDefaultCategories] = useState<any>()
    const [selectedProLocal, setSelectedProLocal] = useState<any>()



    const client = axios.create({
        baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
    })
    // console.log(service)
    const [appointmentActionLoading, setAppointmentActionLoading] = useState(false)

    return (
        <>

            <div className="w-full h-full md:w-[24rem] bg-white rounded-lg p-4 overflow-auto">
                <div className='block md:hidden' onClick={onClose}>
                    <Icon.X />
                </div>

                <div className='w-full mb-4 flex justify-center items-center gap-1 text-error-500'>
                    <p className=" text-lg font-semibold">
                        {`Delete ${service?.name}`}
                    </p>
                    <div className='' onClick={onClose} >
                        <Icon.AlertCircle size={20} color='#F04438' />
                    </div>
                </div>
                <div className='w-full text-center'>
                    <p className="mb-2 text-sm text-gray-600">
                        {`Are you sure you wish to delete the service ${service?.name}?`}
                    </p>
                </div>

                {loadingDetails ?
                    <>
                        <div className='flex flex-1 grow justify-center '>
                            <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                        </div>
                    </> :
                    <>
                        {service ?
                            <>

                                <div className="flex justify-between bg-gray-50 p-4 rounded-lg my-5 mx-4">
                                    
                                        <div>

                                            <div className='flex gap-2'>

                                                <p className='text-lg font-semibold text-gray-900'>
                                                    {service?.name}
                                                </p>
                                            </div>



                                            <div className='flex gap-2'>
                                                <p className='text-sm text-gray-500'>
                                                    {service?.description}
                                                </p>
                                            </div>


                                        </div>
                                        <div className='flex flex-col justify-end items-end'>
                                            <div className='text-sm font-semibold text-gray-700'>
                                                {`${shopsData[selectedShop]?.currency} ${service?.price}`}
                                            </div>
                                            <div className='text-sm text-gray-500'>
                                                {service?.duration}
                                            </div>
                                        </div>
                                    

                                </div>



                                <div>
                                    <Button loading={loading} size='sm' destructive disabled={false} onClick={async () => {
                                    
                                        setLoading(true)
                                        await deleteShopService({ queryKey: ["shopID", selectedShop] }, service.id).then(() => {
                                            showToast("Service deleted", "success")
                                            onDelete && onDelete();
                                        }).catch(() => {
                                            showToast("We couldn't delete the service, please try again.", "success", 7000)
                                        }).finally(() => {
                                            setLoading(false)
                                        })
                                        // console.log(selectedShop)
                                    }}>{`Yes, delete ${service?.name}`}</Button>
                                </div>
                                <div className='mt-2'>
                                    <Button size='sm' secondaryGray disabled={false} onClick={() => {
                                        onCancel && onCancel()
                                    }}>{`Cancel`}</Button>
                                </div>
                            </> :
                            <>
                            </>


                        }
                    </>}




            </div>
        </>
    )
}

export default DeleteService