import React from 'react'

interface AppointmentStatusInterface {
    status: AppointmentStatus,
}
type AppointmentStatus =
    "pending" | "rejected" | "cancelled" | "cancelled_pro"
    | "accepted" | "completed" | "didntShow" | "unknown";


function AppointmentStatusLabel({
    status,
}: AppointmentStatusInterface | any) {
    var bgColor, textColor, text;

    // temporary fix for version of client side that do not set the "status" property of the appointment
    if(status === undefined) {
        bgColor = "bg-green-50";
        textColor = "text-green-700"
        text = "Accepted"
    }


    if (status === 'completed') {
        bgColor = "bg-green-50";
        textColor = "text-green-700"
        text = "Completed"
    } else if (status === 'didntShow') {
        bgColor = "bg-yellow-50";
        textColor = "text-yellow-700"
        text = "Didn't show"
    } else if (status === 'accepted') {
        bgColor = "bg-green-50";
        textColor = "text-green-700"
        text = "Accepted"
    } else if (status === 'cancelled') {
        bgColor = "bg-red-50";
        textColor = "text-red-700"
        text = "Cancelled"
    } else if (status === 'pending') {
        bgColor = "bg-yellow-50";
        textColor = "text-yellow-700"
        text = "Pending"
    } else if (status === 'rejected') {
        bgColor = "bg-red-50";
        textColor = "text-red-700"
        text = "Rejected"
    }
    return (
        <div className={`rounded-full px-4 py-1 ${bgColor} ${textColor} font-semibold text-sm`}>
            {text}
        </div>
    )
}

export default AppointmentStatusLabel