import { firestoreDb } from '../config/firebase.config'
import {
    collection,
    getDocs,
    doc,
    getDoc,
    orderBy,
    limit,
    query,
    where,
    collectionGroup,
    DocumentData,
    onSnapshot,
} from 'firebase/firestore'
import { COLLECTIONS } from '../constants/collections'
import { format } from 'date-fns'
import { flattenBookings, splitEventsByDate } from '../utils/flatten-bookings'
import { Staff } from '../types'
// import { ProfessionalData } from '../types'



/**
 * Fetch upcoming and history appointment from the backend
 * @param  
 * @returns 
 */
export async function fetchBookings({ queryKey }: { queryKey: string[] }) {
    const [_, barberId] = queryKey

    const bookingsRef = collection(
        firestoreDb,
        `${COLLECTIONS.BARBERS}/${barberId}/Bookings`
    )

    const historyRef = collection(
        firestoreDb,
        `${COLLECTIONS.BARBERS}/${barberId}/history`
    )

    // Query upcoming bookings
    const bookingsQuery = query(bookingsRef)
    const querySnapshot = await getDocs(bookingsQuery)
    const finalData: DocumentData[] = []
    querySnapshot?.docs?.forEach((doc) => {
        const flattenedData = flattenBookings(doc.data())
        
        finalData.push(...flattenedData)
    })
   
    // Query upcoming bookings
    const historyQuery = query(historyRef)
    const historySnapshot = await getDocs(historyQuery)
    const historyData: DocumentData[] = []
    historySnapshot?.docs?.forEach((doc) => {
        historyData.push(doc.data())
    })
// console.log("V1", finalData)
    const finalDataWithUsers = await Promise.all(
        finalData.map(async (item) => {
            if (item.clientID) {
                const foundUser = await fetchUserFromBookings({
                    queryKey: ['user_client', item.clientID],
                })
                if (foundUser) {
                    return { ...item, user: foundUser }
                }
            }
            return item
        })
    )

    const historyDataWithUsers = await Promise.all(
        historyData.map(async (item) => {
            
            if (item.clientID) {
                const foundUser = await fetchUserFromBookings({
                    queryKey: ['user_client', item.clientID],
                })
                if (foundUser) {
                    return { ...item, user: foundUser }
                }
            }
            return item
        })
    )

    //const finalObject = splitEventsByDate(finalDataWithUsers)

    // console.log({
    //     upcoming: finalDataWithUsers,
    //     history: historyDataWithUsers,
    // })
    return {
        upcoming: finalDataWithUsers,
        history: historyDataWithUsers,
    }
}




export async function fetchUserFromBookings({
    queryKey,
}: {
    queryKey: string[]
}) {
    const [_, userId] = queryKey

    const bookingRef = doc(firestoreDb, COLLECTIONS.USER_CLIENT, userId)
    const docSnap = await getDoc(bookingRef)
    return docSnap.data()
}

export async function fetchProfessionalsFromShop({
    queryKey,
}: {
    queryKey: string[]
}) {
    const [_, shopID] = queryKey

    const professionalsRef = doc(firestoreDb, COLLECTIONS.BARBER_SHOPS, shopID)
    const docSnap = await getDoc(professionalsRef)
    const shopData = docSnap.data()
    return shopData?.staff
}


export async function fetchUserByID({ queryKey }: { queryKey: string[] }) {
    const [_, userID] = queryKey

    const userRef = doc(firestoreDb, COLLECTIONS.BARBERS, userID)
    const docSnap = await getDoc(userRef)

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null
    }
}

export async function fetchBusinessByID({
    queryKey,
}: {
    queryKey: string[]
}) {
    const [_, barberShopId] = queryKey

    const bookingRef = doc(firestoreDb, COLLECTIONS.BARBER_SHOPS, barberShopId)
    const docSnap = await getDoc(bookingRef)

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return {}
    }
}

export async function fetchBusinessSettings({
    queryKey,
}: {
    queryKey: string[]
}) {
    const [_, businessID] = queryKey

    let settings = {};

    const settingsRef = collection(firestoreDb, COLLECTIONS.BARBER_SHOPS, businessID, "Settings")
    const docSnap = await getDocs(settingsRef)

    docSnap.docs.forEach((doc) => {
        settings = {
            ...settings,
            [doc.id]: doc.data(),
        }
    })

    return settings;
}

export async function fetchClientById({ queryKey }: { queryKey: string[] }) {
    const [_, clientId] = queryKey

    const bookingRef = doc(firestoreDb, COLLECTIONS.USER_CLIENT, clientId)
    const docSnap = await getDoc(bookingRef)

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null
    }
}


