import React from 'react'
import { Oval } from 'react-loader-spinner'

function LoadingScreen() {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-background/50 backdrop-blur-sm">
            <div className='flex flex-col items-center h-full w-full justify-center'>
                <Oval color='#ffffff' secondaryColor='#7D7D7D' width={32} />
                <p className='text-gray-900'>Loading...</p>
            </div>
        </div>
    )
}

export default LoadingScreen