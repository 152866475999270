import React from 'react'
import * as Icon from 'react-feather'

interface AppointmentStatusInterface {
    status: AppointmentStatus,
}
type AppointmentStatus =
    "pending" | "rejected" | "cancelled" | "cancelled_pro"
    | "accepted" | "completed" | "didntShow" | "unknown";

function AppointmentStatusDetails({
    status,
}: AppointmentStatusInterface) {

    var headline: string = "";
    var headlineColor: string = "";
    var subText: string = "";
    var subTextColor: string = "";
    var icon = <Icon.Check />;
    var bgColor = 'bg-green-200';
    var iconBgColor = 'bg-green-400';
    

    if (status === 'completed') {
        icon = <Icon.Check />
        headline = "Appointment completed";
        subText = "This appointment was successfully completed.";
        bgColor = 'bg-green-200';
        headlineColor = "text-green-900";
        subTextColor = "text-green-700"
        iconBgColor = 'bg-green-400';
    } else if (status === 'didntShow') {
        icon = <Icon.UserX />
        headline = "Didn't show";
        subText = "Your client didn't show up for this appointment";
        bgColor = 'bg-yellow-200';
        headlineColor = "text-yellow-900";
        subTextColor = "text-yellow-700"
        iconBgColor = 'bg-yellow-400';
    } else if (status === 'cancelled') {
        icon = <Icon.UserX />
        headline = "Cancelled";
        subText = "This appointment was cancelled by your client";
        bgColor = 'bg-red-200';
        headlineColor = "text-red-900";
        subTextColor = "text-red-700"
        iconBgColor = 'bg-red-400';
    } else if (status === 'rejected') {
        icon = <Icon.UserX />
        headline = "Rejected";
        subText = "This appointment was rejected by the professional";
        bgColor = 'bg-red-200';
        headlineColor = "text-red-900";
        subTextColor = "text-red-700"
        iconBgColor = 'bg-red-400';
    }

    return (
        <div className={`flex items-center rounded-md ${bgColor} p-2 gap-2`}>
            <div className={`flex items-center rounded-full ${iconBgColor} p-2`}>
                {icon}
            </div>
            <div>
                <p className={`${headlineColor} font-semibold`}>
                    {headline}
                </p>
                <p className={`${subTextColor} text-sm`}>
                    {subText}
                </p>
            </div>
        </div>
    )
}

export default AppointmentStatusDetails