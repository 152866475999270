import React, { useEffect, useState } from 'react'
import axios, { AxiosInstance } from 'axios';
import * as Icon from 'react-feather';
// import AnonymousUserImage from '../../assets/images/anonymous.jpeg'

import { Oval } from 'react-loader-spinner'
import { useIsMobile } from '../hooks/useIsMobile';
import { Button } from './button';
import { firestoreDb } from '../config/firebase.config';
import { arrayRemove, collection, doc, updateDoc } from 'firebase/firestore';
import { showToast } from '../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { shopSelector } from '../store/models/shopModel';
import { professionalSelector } from '../store/models/professionalsModel';
import { userSelector } from '../store/models/userModel';

interface IProfessionalDetails {
    selectedPro: any,
    shop: any
    onClose?: () => void,
    onCancel?: () => void,
    onRemove?: () => void,
    loadingDetails: boolean
}

function RemoveProfessional({ selectedPro, onClose, shop, loadingDetails, onCancel, onRemove }: IProfessionalDetails) {

    const { isMobile } = useIsMobile()
    const { selectedShop } = useSelector(shopSelector)
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false)

    const { proIDs, proNames } = useSelector(professionalSelector)
    const { id } = useSelector(userSelector)

    // const [defaultCategories, setDefaultCategories] = useState<any>()
    const [selectedProLocal, setSelectedProLocal] = useState<any>()


    let client: AxiosInstance;

    if (process.env.NODE_ENV === "development") {
        client = axios.create({
            baseURL: "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
        })
    } else {
        client = axios.create({
            baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
        })
    }
    // console.log(selectedPro)
    const [appointmentActionLoading, setAppointmentActionLoading] = useState(false)

    return (
        <>

            <div className="w-full h-full md:w-[24rem] bg-white rounded-lg p-4 overflow-auto">
                <div className='block md:hidden' onClick={onClose}>
                    <Icon.X />
                </div>

                <div className='w-full mb-4 flex justify-center items-center gap-1 text-error-500'>
                    <p className=" text-lg font-semibold">
                        {`Remove ${selectedPro?.name}`}
                    </p>
                    <div className='' onClick={onClose} >
                        <Icon.AlertCircle size={20} color='#F04438' />
                    </div>
                </div>
                <div className='w-full text-center'>
                    <p className="mb-2 text-sm text-gray-600">
                        {`Are you sure you wish to remove ${selectedPro?.name} from this business?`}
                    </p>
                    <p className="mb-2 text-sm text-gray-600">
                        This will revoke their access to your business and they will not appear as your professional anymore
                    </p>
                </div>

                {loadingDetails ?
                    <>
                        <div className='flex flex-1 grow justify-center '>
                            <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                        </div>
                    </> :
                    <>
                        {selectedPro ?
                            <>
                                <div className='flex flex-col items-center justify-between'>
                                    <img
                                        className="object-cover w-16 h-16 rounded-full"
                                        src={
                                            selectedPro?.profilePic ? selectedPro?.profilePic : "https://firebasestorage.googleapis.com/v0/b/barbapp-d4159.appspot.com/o/appImages%2Fuser%20avatar.png?alt=media&token=761601ec-b7f7-4ea0-a96e-45a025c06a5c"
                                        }
                                        alt="pro_picture"
                                    />
                                    <div className='mt-4 font-semibold text-xl'>
                                        {selectedPro.name}
                                    </div>
                                </div>

                                <div className="flex justify-between bg-gray-50 p-4 rounded-lg my-5 mx-4">
                                    <div className="flex items-center gap-4">
                                        <div>

                                            {isMobile ? <>

                                                <div className='flex gap-2'>
                                                    <div className='my-auto'>
                                                        <Icon.Phone size={14} color="#667085" />
                                                    </div>
                                                    <p className='text-sm text-gray-500'>
                                                        {selectedPro?.phone}
                                                    </p>
                                                </div>



                                                <div className='flex gap-2'>
                                                    <div className='my-auto'>
                                                        <Icon.Mail size={14} color="#667085" />
                                                    </div>
                                                    <p className='text-sm text-gray-500'>
                                                        {selectedPro?.email}
                                                    </p>
                                                </div>

                                            </> :
                                                <>
                                                    <a href={`tel:${selectedPro?.Phone_Number}`}>
                                                        <div className='flex gap-2'>
                                                            <div className='my-auto'>
                                                                <Icon.Phone size={14} color="#667085" />
                                                            </div>
                                                            <p className='text-sm text-gray-500'>
                                                                {selectedPro?.phone}
                                                            </p>
                                                        </div>
                                                    </a>

                                                    <a
                                                        href={`mailto:${selectedPro?.email}`}
                                                    >
                                                        <div className='flex gap-2'>
                                                            <div className='my-auto'>
                                                                <Icon.Mail size={14} color="#667085" />
                                                            </div>
                                                            <p className='text-sm text-gray-500'>
                                                                {selectedPro?.email}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </>}

                                        </div>

                                    </div>

                                </div>



                                <div>
                                    <Button loading={loading} size='sm' destructive disabled={false} onClick={async () => {

                                        setLoading(true);

                                        console.log("remove", {
                                            businessID: selectedShop,
                                            userID: selectedPro.id,
                                            adminID: id
                                        })

                                        client.post("/removeUserFromBusiness", {
                                                businessID: selectedShop,
                                                userID: selectedPro.id,
                                                adminID: id
                                            })
                                            .then((res) => {
                                                showToast("User removed", "success")
                                            })
                                            .catch((e) => {
                                                showToast("Couldn't remove user. Try again later.", "error")
                                            })
                                            .finally(() => setLoading(false));

                                        // console.log(selectedShop)

                                        // dispatch.Professionals.setProIDs({
                                        //     ...proIDs,
                                        //     [selectedShop]: proIDs[selectedShop as any].filter((id: string) => {
                                        //         return id !== selectedPro.id
                                        //     })
                                        // })
                                        // dispatch.Professionals.setProNames({
                                        //     ...proNames,
                                        //     [selectedShop]: proNames[selectedShop].filter((name: string) => {
                                        //         return name !== selectedPro.name
                                        //     })
                                        // })

                                        // dispatch.Professionals.removeProfessionalData(selectedPro)

                                    }}>{`Yes, remove ${selectedPro?.name}...`}</Button>
                                </div>
                                <div className='mt-2'>
                                    <Button size='sm' secondaryGray disabled={false} onClick={() => {
                                        onCancel && onCancel()
                                    }}>{`Cancel`}</Button>
                                </div>
                            </> :
                            <>
                            </>


                        }
                    </>}




            </div>
        </>
    )
}

export default RemoveProfessional