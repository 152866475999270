import clsx from 'clsx'
import React from 'react'
import * as Icon from "react-feather"

interface IOnboardingStep {
    step: number,
    title: string,
    done: boolean,
    current: boolean
}

function OnboardingStep({
    step,
    title,
    done,
    current
}: IOnboardingStep) {
    return (
        <div className='flex flex-col gap-2 justify-center items-center transition-all'>
            <div className={
                clsx(
                    "flex justify-center border-2 items-center p-4 rounded-full w-12 h-12 md:w-16 md:h-16",
                    !done && !current && 'border-gray-200',
                    done && !current && 'border-green-300 bg-green-200',
                    !done && current && "border-gray-900",
                    done && current && "border-green-900 bg-green-200",

                )
            }>
                {!done ?
                    <>
                        {step}
                    </>
                    :
                    <>
                        <div className="hidden md:block">
                            <Icon.Check size={24} color='#054F31' />
                        </div>
                        <div className="md:hidden">
                            <Icon.Check size={16} color='#054F31' />
                        </div>
                    </>
                }
            </div>
            <div className='text-gray-600 text-center'>
                {title}
            </div>
        </div>
    )
}

export default OnboardingStep