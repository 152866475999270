// 'use client';

import { useEffect } from 'react';
import { cx } from '../utils/utils';
import { AreaChart } from './tremor/AreaChart';
import { Card } from './tremor/Card';

// import { AreaChart } from '@/components/tremor/AreaChart';
// import { Card } from '../../components/tremor/Card';


// const summary = [
//   {
//     name: 'Organic',
//     value: 3273,
//   },
//   {
//     name: 'Sponsored',
//     value: 120,
//   },
// ];

const valueFormatter = (number: number) =>
  `${Intl.NumberFormat('us').format(number).toString()}`;

type Status = 'realized' | 'expected';

const statusColor: Record<Status, string> = {
  realized: 'bg-blue-500 dark:bg-blue-500',
  expected: 'bg-violet-500 dark:bg-violet-500',
};

interface RevenueHistoryData {
  date: string,
  expected: number,
  realized: number
}

export default function RevenueHistoryChart({ data }: any) {


  const summary = [
    {
      name: "Expected",
      value: data.reduce((sum: number, item: any) => sum + item.expected, 0)
    },
    {
      name: "Realized",
      value: data.reduce((sum: number, item: any) => sum + item.realized, 0)
    }
  ];

  return (
    <>
      {/* <Card className="sm:mx-auto_ sm:max-w-lg_">
        <h1 className="font-medium text-gray-900 dark:text-gray-50">
          Revenue metrics
        </h1> */}
        <AreaChart
          data={data}
          index="date"
          categories={['realized', 'expected']}
          colors={['blue', 'violet']}
          valueFormatter={valueFormatter}
          showLegend={false}
          showYAxis={false}
          startEndOnly={true}
          fill="solid"
          className="mt-6 h-32"
        />
        <ul
          role="list"
          className="mt-4 divide-y divide-gray-200 text-sm text-gray-500 dark:divide-gray-800 dark:text-gray-500"
        >
          {summary.map((item) => (
            <li
              key={item.name}
              className="flex items-center justify-between py-2"
            >
              <div className="flex items-center space-x-2">
                <span
                  className={cx(
                    statusColor[item.name as Status],
                    'h-[3px] w-3.5 shrink-0 rounded-full',
                  )}
                  aria-hidden="true"
                />
                <span className="text-gray-500 dark:text-gray-500">
                  {item.name}
                </span>
              </div>
              <span className="font-medium text-gray-900 dark:text-gray-50">
                {valueFormatter(item.value)}
              </span>
            </li>
          ))}
        </ul>
      {/* </Card> */}
    </>
  );
}