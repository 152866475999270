import React, { useEffect, useRef, useState } from 'react'
import ContentPage from '../../components/common/ContentPage'
import ProfessionalDetails from '../../components/ProfessionalDetails'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '../../store/models/userModel'
import { professionalSelector } from '../../store/models/professionalsModel'
import { shopSelector } from '../../store/models/shopModel'
import { fetchUserByID, fetchBusinessByID } from '../../api/booking'
import { Oval } from 'react-loader-spinner'
import AddProfessional from '../../components/AddProfessional'
import { useIsMobile } from '../../hooks/useIsMobile'

import RemoveProfessional from '../../components/RemoveProfessional'
import { analyticsEvents } from '../../utils/Events'
import { usePostHog } from 'posthog-js/react'
import { useSearchParams } from 'react-router-dom'
import UsersTable from '../../components/table/UsersTable'
import { canAddUser } from '../../utils/roles'


function ShopProfessionals() {
  const posthog = usePostHog();
  const { isMobile } = useIsMobile();
  const { usersData } = useSelector(professionalSelector);
  const { shopsData, selectedShop } = useSelector(shopSelector);

  const [usersList, setUsersList] = useState<any[]>([]);

  const [business, setBusiness] = useState<any>();

  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [loadingDetailsUsers, setLoadingDetailsUsers] = useState<boolean>(true);

  const [addingUser, setAdding] = useState<boolean>(false);
  const [pagePart, setPagePart] = useState<"professionals" | "details" | "add" | "remove">("professionals");

  const selectedUserIndexRef = useRef<any>();

  const { roles } = useSelector(userSelector)


  const fetchProData = async () => {
    await fetchBusinessByID({ queryKey: ["selectedShop", selectedShop] })
      .then(async (shopData) => {
        const professionals: string[] = [];

        // get users data and populate the array of IDs
        Object.values(usersData[selectedShop]).map((userObect: any) => {
          professionals.push(userObect.id);
        });

        // change this
        // fetch users data
        const promises = professionals.map((pro) =>
          fetchUserByID({ queryKey: ["pro", pro] })
        );

        const proDataArray = await Promise.all(promises);
        const pros = proDataArray.map((proData) => proData);
        setUsersList(pros);
        setLoadingUsers(false);
        setLoadingDetailsUsers(false);
        setBusiness(shopData);
      })


  }


  const [selectedPro, setSelectedPro] = useState<any>()
  useEffect(() => {
    setLoadingUsers(true);

    if (selectedShop) {
      fetchProData();
    }

  }, [selectedShop, usersData]);


  const dispatch = useDispatch();

  const { subscription } = useSelector(userSelector);

  useEffect(() => {
    console.log(canAddUser(roles))
  }, [roles])

  const addPro = () => {

    setSelectedPro(null)
    setAdding(true);
    setPagePart("add");
    setSearchParams(params => {
      params.set("action", "add")
      return params
    });

  }



  return (
    <div className='flex grow w-full h-full'>
      <div className={`${(isMobile && pagePart === "professionals") ? "" : "hidden"} md:block flex-1 bg-green-400`}>
        <ContentPage loadingAction={loading} title={`Your users ${shopsData[selectedShop]?.name !== undefined ? `(${shopsData[selectedShop]?.name})` : ""}`} subtitle='Have a look at your users and their data' actionText='Invite a user' showActionButton={canAddUser(roles)} onClickAction={addPro}>
          <div className=' py-6 '>
            {loadingUsers || !usersData[selectedShop] ?
              <>
                <div className='flex flex-1 grow justify-center '>
                  <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                </div>
              </>
              :
              <>
                <div className='lg:flex  gap-4  flex-1 lg:flex-wrap gap-x-4 shrink-0 m-auto'>

                  <UsersTable
                    ref={selectedUserIndexRef}
                    onRowClick={() => {
                      setAdding(false)

                      setTimeout(() => {
                        setSelectedPro(usersList[selectedUserIndexRef.current.getSelectedRowIndex()])
                        console.log(usersList[selectedUserIndexRef.current.getSelectedRowIndex()])
                      }, 100);

                      setPagePart("details")
                      setSearchParams(params => {
                        params.delete("action")
                        return params
                      })

                    }} data={usersList?.map((userData: any) => {
                      return {
                        "name": userData.name,
                        "email": userData.email,
                        "id": userData.id,
                        "roles": usersData[selectedShop][userData.id]?.roles
                      }
                    })} />


                </div>
              </>}
          </div>
        </ContentPage>
      </div>
      {(pagePart === "add" || searchParams.get("action") === "add") &&
        <>
          {business && (
            <div className={`md:ml-4 flex ${(isMobile && pagePart === "add") ? "" : "hidden"} lg:block`}>
              <AddProfessional onClose={() => {
                setPagePart("professionals")
                setSearchParams(params => {
                  params.delete("action")
                  return params
                })
              }}  />
            </div>
          )}
        </>}

      {pagePart === "details" &&
        <>
          <div className={`md:ml-4 w-full md:w-auto flex flex-col ${(isMobile && pagePart === "details") ? "" : "hidden"} lg:block`}>
            <ProfessionalDetails loadingDetails={loadingDetailsUsers} shop={business} selectedPro={selectedPro} onClose={() => {
              setPagePart("professionals")
              setSearchParams(params => {
                params.delete("action")
                return params
              })
            }} onPressRemove={() => {
              setPagePart("remove")
              setSearchParams(params => {
                params.delete("action")
                return params
              })
            }} />
          </div>
        </>
      }
      {pagePart === "remove" &&
        <div className={`md:ml-4 w-full md:w-auto flex flex-col ${(isMobile && pagePart === "remove") ? "" : "hidden"} lg:block`}>
          <RemoveProfessional onRemove={() => {
            fetchProData()
            setPagePart("professionals")
            setSearchParams(params => {
              params.delete("action")
              return params
            })
          }} loadingDetails={loadingDetailsUsers} shop={business} selectedPro={selectedPro} onClose={() => {
            setPagePart("professionals")
            setSearchParams(params => {
              params.delete("action")
              return params
            })
          }} onCancel={() => {
            setPagePart("professionals")
            setSearchParams(params => {
              params.delete("action")
              return params
            })
          }} />
        </div>
      }
    </div>
  )
}

export default ShopProfessionals