import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import * as Icon from "react-feather"

interface IFilterChip {
    children: string,
    onSelect: () => void,
}

function FilterChip({ children, onSelect }: IFilterChip, ref: any) {
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        // console.log(`chip state changed to ${selected}, output should be ${selected ? children : ""}`)
    }, [selected])

    // make the selected index available outside
    useImperativeHandle(ref, () => {
        return {
            getFilterChipSelected: () => selected ? children.split("'")[0] : "",
            deselectChip: () => setSelected(false)
        }
    }, [selected, children]);
    return (
        <div className={`transition flex gap-2 items-center ${selected ? "border-gray-900 bg-gray-700 text-white" : "border-gray-300 bg-white text-gray-900"} rounded-lg border px-2 py-1 text-sm text-gray-500 cursor-pointer`}
            onClick={() => {
                setSelected(!selected)
                onSelect()
            }}>
            <div>{children}</div>
            {selected && <Icon.X size={16} />}
        </div>

    )
}

export default forwardRef(FilterChip)