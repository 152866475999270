import { arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { firestoreDb } from "../config/firebase.config"

/**
 * Updates the shop service in Firebase
 * @param query the query containing the shop ID you wish to update which is of the form { queryKey: ["your_id", the_query_key] }
 * @param oldData the initial state of the service before changes. This is used to remove the old version from th backend
 * @param updateData the data you wish to update
 * @param category the category of the service you're passing. 
 */
export async function updateShopService({
    queryKey,
}: {
    queryKey: string[]
}, service: any, updateData: any, category: string) {
    try {
        const [_, shopID] = queryKey

        const servicesRef = doc(firestoreDb, "Businesses", shopID, "Services", service.id);
       
        await updateDoc(servicesRef, {
            ...updateData
        })
            
    } catch (e) {
        console.log("Couldn't update service.", e)
    }

}

/**
 * Add a service to the shop in Firebase
 * @param query the query containing the shop ID you wish to update which is of the form { queryKey: ["your_id", the_query_key] }
 * @param data the service you wish to add
 * @param category the category of the service you're passing. 
 */
export async function addShopService({
    queryKey,
}: {
    queryKey: string[]
}, data: any, category: string) {
    try {
        const [_, shopID] = queryKey

        const newServiceRef = doc(collection(firestoreDb, "Businesses", shopID, "Services"))

        await setDoc(newServiceRef, {
            ...data,
            id: newServiceRef.id,
            category
        })

    } catch (e) {
        console.log("Couldn't update service.", e)
    }

}

/**
 * Deletes a service in Firebase
 * @param query the query containing the shop ID you wish to update which is of the form { queryKey: ["your_id", the_query_key] }
 * @param data the service you wish to delete
 * @param category the category of the service you're deleting. 
 */
export async function deleteShopService({
    queryKey,
}: {
    queryKey: string[]
}, serviceID: string) {
    try {
        const [_, shopID] = queryKey
        const serviceRef = doc(firestoreDb, "Businesses", shopID, "Services", serviceID)
        // console.log("adding new service:", data)
        await deleteDoc(serviceRef);

    } catch (e) {
        console.log("Couldn't delete service.", e)
    }

}

/**
 * Updates the professional's profile in Firebase
 * @param query the query containing the pro ID you wish to update which is of the form { queryKey: ["your_id", the_query_key] }
 * @param updateData the data you wish to update
 */
export async function updateProfessionalProfile({
    queryKey,
}: {
    queryKey: string[]
}, updateData: any) {
    try {
        const [_, proID] = queryKey

        const proRef = doc(firestoreDb, "Users", proID)
        await updateDoc(proRef, updateData)
    } catch (e) {
        console.log("Couldn't update pro data.", e)
    }

}