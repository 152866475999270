// 'use client';

import { cx } from '../utils/utils';

import { Card } from '../components/tremor/Card'
import { DonutChart } from './tremor/DonutChart';
import { Title } from '@tremor/react';



const valueFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString();

export default function AppointmentCategoryChart({ data }: any) {
  return (
    <>
      <Card className="sm:mx-auto_ sm:max-w-lg_">
        <Title className="text-gray-600">
          Total appointements by category
        </Title>
        <DonutChart
          className="mx-auto mt-8"
          data={data}
          category="name"
          value="count"
          showLabel={true}
          valueFormatter={valueFormatter}
          showTooltip={true}
          colors={['cyan', 'blue', 'gray', 'violet', 'fuchsia']}
        />
        <p className="mt-8 flex items-center justify-between text-xs text-gray-500 dark:text-gray-500">
          <span>Category</span>
          <span>Count</span>
        </p>
        <ul
          role="list"
          className="mt-2 divide-y divide-gray-200 text-sm text-gray-500 dark:divide-gray-800 dark:text-gray-500"
        >
          {data.map((item: any) => (
            <li
              key={item.name}
              className="relative flex items-center justify-between py-2"
            >
              <div className="flex items-center space-x-2.5 truncate">
                <span
                  className={cx(item.color, 'size-2.5 shrink-0 rounded-sm w-3 h-3')}
                  aria-hidden={true}
                />
                <span className="truncate dark:text-gray-300">{item.name}</span>
              </div>
              <p className="flex items-center space-x-2">
                <span className="font-medium tabular-nums text-gray-900 dark:text-gray-50">
                  {valueFormatter(item.count)} appointment(s)
                </span>

              </p>
            </li>
          ))}
        </ul>
      </Card>
    </>
  );
}