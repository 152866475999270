import { createModel } from '@rematch/core'
import { RootState } from '../store'
import { RootModel } from './index'

interface ShopModel {
    shopsData: any,
    selectedShop: string,
    selectedCategory: any,
    categories: string[],
    latestCategory: any
    invites: any[]
}


export const Shop = createModel<RootModel>()({
    state: {
        shopsData: {},
        selectedShop: "",
        selectedCategory: "",
        
    } as ShopModel,
    reducers: {
        saveShops(state, payload) {
            return {
                ...state,
                shopsData: payload
            }
        },
        setSelectedShop(state, payload) {
            return {
                ...state,
                selectedShop: payload
            }
        },
        saveCategories(state, payload) {
            return {
                ...state,
                categories: payload
            }
        },
        addCategory(state, payload) {
            return {
                ...state,
                categories: [...state.categories, payload]
            }
        },
        setSelectedCategory(state, payload) {
            return {
                ...state,
                selectedCategory: payload
            }
        },
        setLatestCategory(state, payload) {
            return {
                ...state,
                latestCategory: payload
            }
        },
        setCurrency(state, payload) {
            return {
                ...state,
                shopsData: {
                    ...state.shopsData,
                    [payload.businessID]: {
                        ...state.shopsData[payload.businessID],
                        currency: payload.currency
                    }
                }
            }
        },
        saveInvites(state, payload) {
            return {
                ...state,
                invites: payload
            }
        },
        saveSettings(state, payload) {
            return {
                ...state,
                shopsData: {
                    ...state.shopsData,
                    [payload.businessID]: {
                        ...state.shopsData[payload.businessID],
                        settings: payload.settings
                    }
                }
            }
        },
    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        // async incrementAsync(payload, rootState) {
        //   await new Promise((resolve) => setTimeout(resolve, 1000));
        //   dispatch.count.increment(payload);
        // },
    }),
})

export const shopSelector = (state: RootState) => state.Shop
