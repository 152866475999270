import { Models } from '@rematch/core'
import { User } from './userModel'
import { Services } from './Services'
import { Shop } from './shopModel'
import { AppPage } from './appPage'
import { AppointmentModel } from './appointmentsModel'
import { Professionals } from './professionalsModel'
import { Modals } from './modals'
import { Dashboard } from './dashboardModel'

export interface RootModel extends Models<RootModel> {
    User: typeof User
    Services: typeof Services
    Shop: typeof Shop
    AppPage: typeof AppPage,
    AppointmentModel: typeof AppointmentModel
    Professionals: typeof Professionals
    Modals: typeof Modals
    Dashboard: typeof Dashboard

}
export const models: RootModel = { User, Services, Shop, AppPage, AppointmentModel, Professionals, Modals, Dashboard }
