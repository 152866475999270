import { createModel } from '@rematch/core'
import { RootState } from '../store'
import { RootModel } from './index'
import { UserType } from './userModel'


interface ProfessionalsModel {
    proIDs: any,
    selectedPro: string,
    selectedProIndex: number,
    // usersData: UserType[],
    proNames: any,
    usersData: any,
}


export const Professionals = createModel<RootModel>()({
    state: {
        proIDs: {},
        selectedPro: "",
        selectedProIndex: 0,
        proNames: {},
        usersData: {}
    } as ProfessionalsModel,
    reducers: {
        setProIDs(state, payload) {
            return {
                ...state,
                proIDs: payload
            }
        },
        setProNames(state, payload) {
            return {
                ...state,
                proNames: payload 
            }
        },
        
        setSelectedPro(state, payload) {
            return {
                ...state,
                selectedPro: payload
            }
        },
        selectedProIndex(state, payload) {
            return {
                ...state,
                selectedProIndex: payload ? payload : 0
            }
        },
        // setUsersData(state, payload) {
        //     return {
        //         ...state,
        //         usersData: payload ? payload : []
        //     }
        // },

        setUsersData(state, payload) {
            return {
                ...state,
                usersData: payload
            }
        },
        removeUserData(state, payload) {
            const id = payload.id;
            delete state.usersData[id]
            return {
                ...state,

            }
        },
    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        // async incrementAsync(payload, rootState) {
        //   await new Promise((resolve) => setTimeout(resolve, 1000));
        //   dispatch.count.increment(payload);
        // },
    }),
})

export const professionalSelector = (state: RootState) => state.Professionals
