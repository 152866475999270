
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { auth } from '../../config/firebase.config'
import { useIsMobile } from '../../hooks/useIsMobile'
import { AppPageSelector } from '../../store/models/appPage'
import { userSelector } from '../../store/models/userModel'
import { analyticsEvents } from '../../utils/Events'
import { Button } from '../button'
import Sidebar from '../common/Sidebar'
import Header from '../Header'
import UpgradeModal from '../modals/UpgradeModal'
import { usePostHog } from 'posthog-js/react'
import NewCategoryModal from '../modals/NewCategoryModal'
import CategoriesModal from '../modals/CategoriesModal'
import EditCategoryModal from '../modals/EditCategoryModal'
import ChooseCategoryModal from '../modals/ChooseCategoryModal'
import Onboarding from '../modals/Onboarding'

export const MainLayout = () => {
    const { sideBarOpen, pagePart } = useSelector(AppPageSelector)
    const { onboarded, email } = useSelector(userSelector)
    const dispatch = useDispatch()
    const { isMobile } = useIsMobile()
    const posthog = usePostHog()
    const location = useLocation()

    useEffect(() => {
        //   console.log(location.pathname)
        dispatch.AppPage.changeAppPage(location.pathname)
    }, [location])

    const logout = () => {
        auth.signOut()
        dispatch.User.saveUser({})

        analyticsEvents.logout();
        posthog.capture("Logout")
    }

    return (

        <div className="max-h-screen h-screen h-full flex relative ">

                <>
                    <aside className={`h-screen ${sideBarOpen ? "xl:hidden block" : "hidden xl:block"} top-0 left-0 z-40`}>
                        <Sidebar />
                    </aside>

                    <main className='w-full  overflow-auto'>
                        <Header pageParts={pagePart} isMobile={isMobile} onOpenMenu={() => {
                            dispatch.AppPage.setSideBarState(true)
                        }}
                            onCloseMenu={() => {
                                // handlePageUpdate(PageParts.BOOKINGS)
                                // setShowMenuDesktop(false)
                                // setShowTabletMenu(true)
                                dispatch.AppPage.setPagePart("bookings")
                            }} />
                        <Outlet />
                    </main>
                    <Toaster />
                    <UpgradeModal />
                    <NewCategoryModal />
                    <CategoriesModal />
                    <Onboarding />
                    <EditCategoryModal />
                
                </>


            


        </div>
    )
}
