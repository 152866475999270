import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { RootState } from "../store";

export const AppPage = createModel<RootModel>()({
    state: {
        page: "",
        sideBarOpen: false,
        pagePart: "bookings"
    },
    reducers: {
        changeAppPage: (state, payload)  => {
            // console.log("Changing app page to", payload)
            return {
                ...state,
                page: payload
            }
        },
        setSideBarState: (state, payload)  => {
            // console.log("side bar open", payload)
            return {
                ...state,
                sideBarOpen: payload
            }
        },
        setPagePart: (state, payload)  => {
            // console.log("page part", payload)
            return {
                ...state,
                pagePart: payload
            }
        },
    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        // async incrementAsync(payload, rootState) {
        //   await new Promise((resolve) => setTimeout(resolve, 1000));
        //   dispatch.count.increment(payload);
        // },
    }),
})

export const AppPageSelector = (state: RootState) => state.AppPage