import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import * as Icon from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import { modalSelector } from '../../store/models/modals'
import { userSelector } from '../../store/models/userModel'
import { analyticsEvents } from '../../utils/Events'
import { Button } from '../button'
import { usePostHog } from 'posthog-js/react'
import { shopSelector } from '../../store/models/shopModel'
import { deleteCategory, fetchCategories, fetchOnboarding, setAccountActivated, setOnboardingStep, startTrial } from '../../api/api'
import { showToast } from '../../utils/utilities'
import { canAddCategory, canEditCategory, canEditService, canRemoveCategory } from '../../utils/roles'
import clsx from 'clsx'
import OnboardingContent from '../onboarding/OnboardingContent'
import OnboardingStep from '../onboarding/OnboardingStep'
import TextInput from '../input/TextInput'
import BusinessProfileOnboarding from '../onboarding/BusinessProfileOnboarding'
import UsersOnboarding from '../onboarding/UsersOnboarding'
import ServicesOnboarding from '../onboarding/ServicesOnboarding'
import BillingOnboarding from '../onboarding/BillingOnboarding'
import OnboardingDone from '../onboarding/OnboardingDone'
import WelcomeOnboarding from '../onboarding/WelcomeOnboarding'

function Onboarding() {

    const Steps = [
        {
            step: "business_profile",
            title: "Business profile",
            showStep: true
        },
        // {
        //     step: "add_users",
        //     title: "Add users",
        //     showStep: true
        // },
        {
            step: "welcome",
            title: "Welcome!",
            showStep: true
        },
        // {
        //     step: "add_services",
        //     title: "Add services",
        //     showStep: true
        // },
        // {
        //     step: "billing",
        //     title: "Billing",
        //     showStep: true
        // },
        {
            step: "done",
            title: "You're all set!",
            showStep: false
        },
    ]

    const { roles, businesses } = useSelector(userSelector)
    const { selectedShop } = useSelector(shopSelector)

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);

    
    const [modal, setModal] = useState(false)
    const posthog = usePostHog()

    const { onboardingModal } = useSelector(modalSelector)

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const toggleModal = () => {
        dispatch.Modals.toggleCategoriesModal()
    }


    const fetchOnboaringState = async (businessesID: string) => {
        const onboarding = await fetchOnboarding(businessesID)
        return onboarding;
    }

    useEffect(() => {
        if (selectedShop) {
            fetchOnboaringState(selectedShop)
                .then((state: any) => {
                    Object.keys(state).map((key: string) => {
                        /*
                        a bit complex but it works, i guess
                        map out the steps based on their keys
                        extract the number in the key to see which steps corresponds "step1" "step2" etc
                        check if the completed flag is true, if yes add it to the list of completed steps
                        and set the current step to the following one
                        */

                        if (key !== "activated") {

                            const numberStr = key.replace(/\D/g, '');
                            const number = parseInt(numberStr) - 1;

                            if (state[key].completed) {
                                setCompletedSteps((curr) => {
                                    let steps = curr;
                                    steps.push(number)
                                    return steps
                                })

                                setCurrentStep(number + 1);
                            }
                        }
                    })
                })
                .catch((e) => {
                    console.log("Can't fetch onbaording:", e);
                })
        }
    }, [selectedShop])


    return (
        <>
            {onboardingModal &&
                <>
                    <div className="absolute w-full h-screen z-[39] opacity-50 bg-gray-900  flex justify-center items-center">

                    </div>
                    <div className="absolute py-6 md:py-0 w-full z-[49] h-screen backdrop-blur-sm flex justify-center items-center">


                        <div className='gap-2 p-6 w-full max-w-4xl max-h-screen  overflow-auto flex flex-col justify-start bg-white rounded-lg mx-4'>
                            <div className='w-full flex gap-12 justify-around items-center'>
                                {Steps.map((step: { step: string, title: string, showStep: boolean }, index) => {
                                    return <>
                                        {step.showStep &&
                                            <OnboardingStep current={index === currentStep} title={step.title} step={index + 1} done={completedSteps.includes(index)} />
                                        }
                                    </>
                                })}
                            </div>
                            {currentStep === 0 &&
                                <OnboardingContent title={Steps[0].title}>
                                    <BusinessProfileOnboarding onNext={async () => {
                                        setCurrentStep(1);
                                        setCompletedSteps((curr) => {
                                            let steps = curr;
                                            steps.push(currentStep)
                                            return steps
                                        })

                                        await setOnboardingStep(selectedShop, 1, {
                                            "completed": true,
                                            "description": "Business profile",
                                        })

                                        posthog.capture("Set up business - Onboarding")

                                    }} />
                                </OnboardingContent>
                            }

                            {/* {currentStep === 1 &&
                                <>
                                    <OnboardingContent title={Steps[1].title}>
                                        <UsersOnboarding onNext={async () => {
                                            setCurrentStep(2);
                                            setCompletedSteps((curr) => {
                                                let steps = curr;
                                                steps.push(currentStep)
                                                return steps
                                            })

                                            await setOnboardingStep(selectedShop, 2, {
                                                "completed": true,
                                                "description": "Add staff",
                                            })

                                            posthog.capture("Add staff - Onboarding")
                                        }}

                                            onPrevious={() => {
                                                setCurrentStep(0);
                                            }} />

                                    </OnboardingContent>
                                </>
                            } */}
                            {/* {currentStep === 1 &&
                                <>
                                    <OnboardingContent title={Steps[1].title}>
                                        <ServicesOnboarding onNext={async () => {
                                            setCurrentStep(2);
                                            setCompletedSteps((curr) => {
                                                let steps = curr;
                                                steps.push(currentStep)
                                                return steps
                                            })

                                            await setOnboardingStep(selectedShop, 2, {
                                                "completed": true,
                                                "description": "Add service",
                                            });

                                            await setAccountActivated(selectedShop);

                                            posthog.capture("Add service - Onboarding")

                                            posthog.capture("Activated account")
                                        }}



                                            onPrevious={() => {
                                                setCurrentStep(0);
                                            }} />
                                    </OnboardingContent>
                                </>
                            } */}
                            {currentStep === 1 &&
                                <>
                                    <OnboardingContent title={Steps[1].title}>
                                        <WelcomeOnboarding onNext={async () => {
                                            setCurrentStep(2);
                                            setCompletedSteps((curr) => {
                                                let steps = curr;
                                                steps.push(currentStep)
                                                return steps
                                            })

                                            await setOnboardingStep(selectedShop, 2, {
                                                "completed": true,
                                                "description": "Welcome",
                                            });

                                            await setAccountActivated(selectedShop);

                                            posthog.capture("Welcome - Onboarding")

                                            posthog.capture("Activated account")
                                        }}



                                            onPrevious={() => {
                                                setCurrentStep(0);
                                            }} />
                                    </OnboardingContent>
                                </>
                            }
                            {/* {currentStep === 3 &&
                                <>
                                    <OnboardingContent title={Steps[3].title}>
                                        <BillingOnboarding onNext={async () => {
                                            setCurrentStep(4);
                                            setCompletedSteps((curr) => {
                                                let steps = curr;
                                                steps.push(currentStep)
                                                return steps
                                            })

                                            await setOnboardingStep(selectedShop, 4, {
                                                "completed": true,
                                                "description": "Billing",
                                            })


                                        }}

                                            onSkip={async () => {
                                                setCurrentStep(4);
                                                setCompletedSteps((curr) => {
                                                    let steps = curr;
                                                    steps.push(currentStep)
                                                    return steps
                                                })

                                                await setOnboardingStep(selectedShop, 4, {
                                                    "completed": true,
                                                    "description": "Billing",
                                                })


                                            }}

                                            onPrevious={() => {
                                                setCurrentStep(2);
                                            }} />
                                    </OnboardingContent>
                                </>
                            } */}
                            {currentStep === 2 &&
                                <>
                                    <OnboardingContent title={Steps[2].title}>
                                        <OnboardingDone onNext={async () => {
                                            dispatch.Modals.toggleOnboardingModal(false);

                                        }}

                                        />
                                    </OnboardingContent>
                                </>
                            }



                        </div>


                    </div>
                </>
            }

        </>
    )
}

export default Onboarding