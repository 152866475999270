import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useReactTable, createColumnHelper, getCoreRowModel, flexRender, getSortedRowModel, SortingState } from '@tanstack/react-table'
import moment from 'moment'
import TableHeader from './TableHeader'
import { useSelector } from 'react-redux'
import { useIsMobile } from '../../hooks/useIsMobile'
import { shopSelector } from '../../store/models/shopModel'
import { userSelector } from '../../store/models/userModel'

type User = {
    name: string
    email: string
    roles: string[],
}

type TUserTable = {
    data: any,
    currentUser?: () => number,
    onRowClick?: () => void
}

const columHelper = createColumnHelper<User>()


const tableColumns = [
    columHelper.accessor("name", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: (header) =>
            <div onClick={header.column.getToggleSortingHandler()}>
                <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Name</TableHeader>
            </div>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("email", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Email</TableHeader>
        </div>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("roles", {
        cell: info => <div className='text-xs text-gray-500'>{`${info.getValue()}`}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Roles</TableHeader>
        </div>,
        footer: info => info.column.id,

    }),
    // columHelper.accessor("duration", {
    //     cell: info => <div className='text-sm text-gray-900'>{`${Math.floor(parseInt(info.getValue()) / 60) === 0 ? "" : `${Math.floor(parseInt(info.getValue()) / 60)}h`}${parseInt(info.getValue()) % 60 === 0 ? "" : `${parseInt(info.getValue()) % 60}mins`}`}</div>,
    //     header: () => <TableHeader>Duration</TableHeader>,
    //     footer: info => info.column.id,
    // }),

]

const tableColumnsMobile = [
    columHelper.accessor("name", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: (header) =>
            <div onClick={header.column.getToggleSortingHandler()}>
                <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Name</TableHeader>
            </div>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("email", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Email</TableHeader>
        </div>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("roles", {
        cell: info => <div className='text-xs text-gray-500'>{`${info.getValue()}`}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Roles</TableHeader>
        </div>,
        footer: info => info.column.id,

    }),

]

function UsersTable({ data, onRowClick }: TUserTable, ref: any) {

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

    const { isMobile } = useIsMobile()

    const [sorting, setSorting] = useState<SortingState>([]);

    const actualData: User[] | any = [];
    const [tableData, setTableData] = useState<User[]>(actualData)
    // filter out the data to keep only what is necessary  

    const { id } = useSelector(userSelector)

    if (data) {
        data?.map((dataItem: any, index: number) => {
            // console.log(dataItem)
            if (dataItem.id === id) {
                actualData.push({
                    name: dataItem?.name + " (you)",
                    email: dataItem?.email,
                    roles: dataItem?.roles?.map((role: any) => role.toLowerCase().charAt(0).toUpperCase() + role.slice(1)).reduce((a: string, b: string) => `${a}, ${b}`)
                })
            } else {
                actualData.push({
                    name: dataItem?.name,
                    email: dataItem?.email,
                    roles: dataItem?.roles?.map((role: any) => role.toLowerCase().charAt(0).toUpperCase() + role.slice(1)).reduce((a: string, b: string) => `${a}, ${b}`)
                })
            }


        })


    }

    // create the table using the library's hook
    const table = useReactTable({

        data: tableData,
        columns: isMobile ? tableColumnsMobile : tableColumns,
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
        onSortingChange: setSorting,
    })


    // pass up the current index of the data to show

    // make the selected index available outside
    useImperativeHandle(ref, () => {
        return {
            getSelectedRowIndex: () => selectedRowIndex,
            resetTableSelection: () => setSelectedRowIndex(0)
        }
    }, [selectedRowIndex]);

    return (
        <div className='flex w-full '>
            <table className='w-full border-collapse overflow-auto'>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} >
                            {headerGroup.headers.map(header => (
                                <th key={header.id} className='p-0'>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    {/* {header.column.getIsSorted() === "asc" ? "⬆" : "⬇"} */}

                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody onClick={onRowClick}>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id} onClick={() => {
                            table.toggleAllRowsSelected(false)
                            row.toggleSelected()
                            setSelectedRowIndex(row.index)

                        }} className={`${row.getIsSelected() && ("bg-gray-200 hover:bg-gray-200")} p-0 hover:bg-gray-100 cursor-pointer`}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className='p-0'>
                                    <div className='h-16 flex items-center px-4 border-2 border-[#EAECF0]/[.0] border-b-gray-100'>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default forwardRef(UsersTable)