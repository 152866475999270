import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as Icon from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import { modalSelector } from '../../store/models/modals'
import { userSelector } from '../../store/models/userModel'
import { analyticsEvents } from '../../utils/Events'
import { Button } from '../button'
import { usePostHog } from 'posthog-js/react'
import { Plans } from '../../pages/plans/PlansPage'

function UpgradeModal() {

    const [modal, setModal] = useState(false)

    const posthog = usePostHog()

    const { upgradeModal } = useSelector(modalSelector)
    const { subscription, email } = useSelector(userSelector);
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const toggleModal = () => {
        dispatch.Modals.toggleUpgradeModal()
    }


    const [upgradeLink, setUpgradeLink] = useState("")

    useEffect(() => {
        const fetchUpgradeLink = async () => {
            setLoading(true)
            const res = await axios.post("https://us-central1-barbapp-d4159.cloudfunctions.net/subscribe",
                {
                    "data": {
                        "email": email,
                        "amount": "500",
                        "plan": "PLN_ip5u2533od0laq2"
                    }
                })
            setUpgradeLink(res.data.data.authorization_url)
            if (res) {
                setLoading(false)
            }
        }

        if (email) fetchUpgradeLink()
    }, [email]);


    return (
        <>
            {upgradeModal &&
                <>
                    <div className="absolute w-full h-screen z-40 opacity-50 bg-gray-900  flex justify-center items-center">

                    </div>
                    <div className="absolute w-full h-screen z-50 backdrop-blur-sm flex justify-center items-center">

                        <div className='gap-2 p-6 w-fit max-w-5xl max-h-screen overflow-auto flex flex-col justify-start bg-white rounded-lg mx-4'>
                            <div className='w-full flex justify-end'>
                                <div className="cursor-pointer"
                                    onClick={toggleModal}
                                >
                                    <Icon.X color='#98a2b3' size={16} />
                                </div>
                            </div>
                            <div className='font-semibold text-xl'>
                                Upgrade to Wavier Pro
                            </div>
                            <p>
                                Ready to unlock more features and take your salon to the next level? Upgrade now to continue enjoying Wavier's full suite of tools and benefits!
                            </p>

                            <Plans />


                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default UpgradeModal