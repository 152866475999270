import { createModel } from '@rematch/core'
import { RootState } from '../store'
import { RootModel } from './index'
import { Owner, Staff, Manager } from '../../types'
import { fetchRoles } from '../../api/api'


export const User = createModel<RootModel>()({
    state: {} as any,
    // state: {} as any,
    reducers: {
        saveUser(state, payload) {
            return {
                ...payload
            }
        },
        setOnboarding(state, payload) {
            return {
                ...state,
                onboarded: payload
            }
        },
        setBusinesses(state, payload) {
            return {
                ...state,
                businesses: payload
            }
        },
        setRoles(state, payload) {

            return {
                ...state,
                roles: payload
            }
        }
    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async getRoles(payload) {
            const usersRoles = await fetchRoles(payload.businessID)
            dispatch.User.setRoles(usersRoles)
        }
    }),
})

export const userSelector = (state: RootState) => state.User
