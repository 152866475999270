import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from '../../config/firebase.config'
import { useIsMobile } from '../../hooks/useIsMobile'
import { AppPageSelector } from '../../store/models/appPage'
import { userSelector } from '../../store/models/userModel'
import Header from '../Header'
import ShopDropdown from '../ShopDropdown'
import SideBarItem from '../SideBarItem'
import * as Icon from 'react-feather';
import { analyticsEvents } from '../../utils/Events'
import { usePostHog } from 'posthog-js/react'
import { canAddService, canAddUser, canEditBusinessProfile, canSeeAllCustomers } from '../../utils/roles'
import UpgradeButton from '../UpgradeButton'
import { shopSelector } from '../../store/models/shopModel'

function Sidebar() {
    const posthog = usePostHog();
    const { page } = useSelector(AppPageSelector);
    const user = useSelector(userSelector);

    const { shopsData, selectedShop } = useSelector(shopSelector);
    const [subscription, setSubscription] = useState<any>({})
    useEffect(() => {
        setSubscription(shopsData[selectedShop].settings.subscription);
    }, [shopsData, selectedShop])

    const dispatch = useDispatch();

    const logout = () => {
        auth.signOut()
        dispatch.User.saveUser({})

        analyticsEvents.logout();
        posthog.capture("Logout");
    }

    return (
        <div className='w-full md:w-64 absolute xl:sticky top-0 z-30'>

            <nav className="shrink-0 bg-gray-900 w-full h-screen ">
                <div className='p-8 flex justify-end w-full xl:hidden' onClick={() => {
                    dispatch.AppPage.setSideBarState(false)
                }}>
                    <Icon.X color='#ffffff' />
                </div>
                <div className='font-pacifico flex items-center w-full text-white text-3xl mx-6 lg:pt-12'>
                    Wavier
                </div>

                <div className='py-8'>
                    <ShopDropdown shopIDs={user.businesses} />

                    {subscription?.billingState === "inactive" &&
                        <div className='mx-6 my-4'>
                            <div className='text-sm text-gray-500'>
                                You're not subscribed. Your account is limited.
                            </div>
                            <div className='max-w-md mt-4 px-8 py-2 flex justify-center items-center rounded-lg  bg-gray-800  text-white'>

                                <UpgradeButton color='white' />
                            </div>
                        </div>

                    }
                </div>



                <div className='gap-2 flex flex-col'>

                    <SideBarItem
                        text="Dashboard"
                        pageName="dashboard"
                        icon={<Icon.BarChart2
                            stroke={'white'}
                        />}
                        active={page === "/app/dashboard"}
                    />

                    <SideBarItem
                        text="Upcoming"
                        pageName="booking"
                        icon={<Icon.Calendar
                            stroke={'white'}
                        />}
                        active={page === "/app/booking"}
                    />
                    <SideBarItem
                        text="History"
                        pageName="history"
                        icon={<Icon.RotateCcw stroke={'white'} />}
                        active={page === "/app/history"}

                    />
                    {canSeeAllCustomers(user.roles) &&
                        <SideBarItem
                            text="Customers"
                            pageName="customers"
                            icon={<Icon.Users
                                stroke={'white'}
                            />}
                            active={page === "/app/customers"}
                        />
                    }

                    <SideBarItem
                        text={(
                            (canEditBusinessProfile(user.roles) ||
                                canAddUser(user.roles) ||
                                canAddService(user.roles))
                                ? "Manage business" :
                                "Business info"
                        )}
                        pageName="settings/profile"
                        icon={<Icon.Settings stroke={'white'} />}
                        active={page.includes("/settings")}
                        onClick={() => {
                            dispatch.AppPage.changeAppPage("/settings/page")
                        }}
                    />

                    <SideBarItem
                        text="Profile"
                        pageName="profile"
                        icon={<Icon.User stroke={'white'} />}
                        active={page.includes("app/profile")}
                        onClick={() => {
                            dispatch.AppPage.changeAppPage("/settings/page")
                        }}
                    />
                    <SideBarItem
                        text="Help"
                        pageName="help"
                        icon={<Icon.HelpCircle stroke={'white'} />}
                        active={page === "/settings/help"}

                    />
                </div>
                <button
                    className="absolute px-4 py-2 ml-8 font-semibold text-white bg-gray-800 rounded-lg bottom-8 hover:bg-gray-700"
                    onClick={logout}
                >
                    Log out
                </button>
            </nav>

        </div>
    )
}

export default Sidebar