import React, { useRef, useState, useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import ContentPage from '../../components/common/ContentPage'
import Section from '../../components/common/Section'
import UpgradeModal from '../../components/modals/UpgradeModal'
import { useIsMobile } from '../../hooks/useIsMobile'
import { userSelector } from '../../store/models/userModel'
import PhoneInput from 'react-phone-input-2'
import TextInput from '../../components/input/TextInput'
import { Button } from '../../components/button'
import axios, { AxiosInstance } from 'axios'
import { usePostHog } from 'posthog-js/react'
import Select from 'react-select'
import currencies from "../../utils/currencies.json"
import { showToast } from '../../utils/utilities'
import { useNavigate } from 'react-router-dom'

function AddShop() {
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const { isMobile } = useIsMobile()

    const navigate = useNavigate()

    const { subscription, id } = useSelector(userSelector);
    const businessNameRef = useRef<any>()
    const businessEmailRef = useRef<any>()
    const businessCountryRef = useRef<any>()

    const [businessName, setBusinessName] = useState<string>('');
    const [businessEmail, setBusinessEmail] = useState<string>('');
    const [businessCountry, setBusinessCountry] = useState<string>('');

    const [businessPhoneNumber, setBusinessPhoneNumber] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)


    let client: AxiosInstance;
    if (process.env.NODE_ENV === "development") {
        client = axios.create({
            baseURL: "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
        })
    } else {
        client = axios.create({
            baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
        })
    }

    const setUpBusiness = async () => {
        try {
            setLoading(true)
            const res = await client.post("/setUpBusiness",
                {
                    "data": {
                        "email": businessEmailRef.current.getInputText() ? businessEmailRef.current.getInputText() : "",
                        "ownerID": id,
                        "currency": selectedCurrency.value ? selectedCurrency.value : "",
                        "name": businessNameRef.current.getInputText() ? businessNameRef.current.getInputText() : "",
                        "phone": businessPhoneNumber ? businessPhoneNumber : "",
                        "country": businessCountry ? businessCountry : "",
                    }
                });

            posthog.capture("Created and added a business")
            showToast(`${businessNameRef.current.getInputText()} was added as your business!`, 'success')
            dispatch.User.setBusinesses([res.data.businessID])
            // dispatch.User.setOnboarding(true)
            dispatch.Shop.setSelectedShop(res.data.businessID)
            navigate("/app/booking")
            setLoading(false)
        } catch (e) {
            console.log("Couldn't set up business. Error:", e);
            setLoading(false)
        }
    }

    const [currencyList, setCurrencyList] = useState<any>([])
    const [selectedCurrency, setSelectedCurrency] = useState<any>()
    useEffect(() => {
        const currencyArray = Object.keys(currencies).map((currency: string) => {
            // @ts-ignore
            const symbol = currencies[currency as any].symbol
            // @ts-ignore
            const name = currencies[currency as any].name
            return {
                label: `${name} (${symbol})`,
                value: symbol
            }
        })
        setCurrencyList(currencyArray)
    }, [])


    return (
        <div className="h-screen bg-gray-50">


            <main className="flex flex-1 h-full">
                {/* Main body */}
                <div className='flex flex-col w-full'>
                    <div className='flex '>
                        <div className="w-full h-screen pt-4 border-gray-100 flex flex-col ">
                            <div className="py-2 px-6">
                                <p className={`mb-2 text-base font-semibold leading-6 ${isMobile ? "text-xl" : "text-2xl"}`}>
                                    {"Add a business"}
                                </p>
                            </div>


                            <div className='bg-white mx-2 mt-2 py-2 px-4 rounded-lg h-full'>
                                {subscription.plan === "free" ?
                                    <>
                                        <p>Upgrade your plan to add a new business</p>
                                        <p>{`You can update your plan by going to Shop > Subscription & Billing and choose the plan you wish to upgrade to`}</p>
                                    </>
                                    :
                                    <ContentPage title={'Let us know a bit about the business you want to add'} actionText={''}>
                                        <div className='sm:px-6 sm:pt-6 p-4 max-w-2xl'>
                                            <Section title={'Complete this (very) simple form to add a new business'} subtitle="We'll create it for you, and you can set it up! Your business will be added to your account.">
                                                <div className='flex-1 max-w-2xl'>

                                                    <div className="relative mb-2">
                                                        <TextInput ref={businessNameRef} onChange={() => setBusinessName(businessNameRef.current.getInputText())} label='Business Name' />
                                                    </div>

                                                    {/* <p className="mb-2 text-sm font-medium">
                                    Number of staff
                                </p>
                                <input type='range' min={1} max={20} /> */}

                                                    <div className="relative mb-2">
                                                        <TextInput onChange={() => setBusinessEmail(businessEmailRef.current.getInputText())} type={"email"} ref={businessEmailRef} label='Business Email address' />
                                                    </div>
                                                    <div className="relative mb-2 ">
                                                        <p className="mb-2 text-sm font-medium">
                                                            Business Phone number
                                                        </p>
                                                        <div className="">
                                                            <PhoneInput
                                                                onChange={setBusinessPhoneNumber}
                                                                placeholder="Business Phone number"
                                                                specialLabel={''}
                                                                country={'gh'}
                                                                inputStyle={{
                                                                    width: '100%',
                                                                    height: '42px',
                                                                    borderRadius: '8px',
                                                                    fontFamily: 'Outfit'
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="relative mb-2">
                                                        <TextInput onChange={() => setBusinessCountry(businessCountryRef.current.getInputText())} type={"email"} ref={businessCountryRef} label='Country' />
                                                    </div>

                                                    <p className="mb-2 text-sm font-medium">
                                                        Preferred currency
                                                    </p>
                                                    <div className="max-w-sm space-y-2">
                                                        <Select options={currencyList} onChange={(change) => {
                                                            setSelectedCurrency(change)
                                                            // console.log(change)
                                                        }}
                                                            value={selectedCurrency}
                                                        />

                                                    </div>

                                                    {
                                                          (businessName.length === 0) &&
                                                        //   (businessPhoneNumber.length === 0) &&
                                                          (businessEmail.length === 0) &&

                                                        <p className="text-xs text-error-500 mt-3">All fields are required</p>
                                                    }
                                                    <div className='mt-4'>
                                                        <Button disabled={
                                                            businessName.length === 0 &&
                                                            // businessPhoneNumber.length === 0 &&
                                                            businessEmail.length === 0
                                                        } loading={loading} onClick={() => {
                                                            // signUp(searchParams.get('plan') === "pro" ? "pro" : "")

                                                            setUpBusiness()
                                                        }} size={"sm"}>Add my business</Button>
                                                    </div>
                                                </div>
                                            </Section>
                                        </div>
                                    </ContentPage>
                                }
                            </div>

                        </div>
                    </div>
                </div>

            </main >

            <Toaster />
        </div >
    )
}

export default AddShop