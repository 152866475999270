import React from 'react'
import * as Icon from "react-feather"
import { Button } from './button'

interface EmptyStateNoticeProps {
    icon: "x" | "?"
    headline: string,
    body?: string,
    action?: string,
    showAction?: boolean,
    onPressAction?: () => void
}

function EmptyStateNotice({ icon, headline, body, action, onPressAction, showAction }: EmptyStateNoticeProps) {
    return (
        <div className="w-96 flex flex-col flex-1 justify-center items-center mx-auto py-4 text-center">
            <div className='border-8 w-16 h-16 flex justify-center items-center bg-primary-100 border-primary-50 rounded-full mb-5'>
                {icon === "x" && <Icon.XCircle size={30} />}
            </div>
            <>
                <p className="mb-2 text-md leading-6 font-semibold ">{headline}</p>
                <p className="mb-2 text-sm leading-6 text-gray-500 ">{body}</p>
            </>
            {(showAction) && action && <Button disabled={false} onClick={() => {
                onPressAction && onPressAction()
            }} children={action} hug size={'sm'} />}
        </div>
    )
}

export default EmptyStateNotice