import React, { useDebugValue, useEffect, useRef, useState } from 'react'
import axios, { AxiosInstance } from 'axios';
import * as Icon from 'react-feather';
// import AnonymousUserImage from '../../assets/images/anonymous.jpeg'

import { Oval } from 'react-loader-spinner'
import { useIsMobile } from '../hooks/useIsMobile';
import TextInput from './input/TextInput';
import { Staff } from '../types';
import { showToast } from '../utils/utilities';
import { Button } from './button';

import { useDispatch, useSelector } from 'react-redux';
import { professionalSelector } from '../store/models/professionalsModel';
import { shopSelector } from '../store/models/shopModel';
import { canAddUser } from '../utils/roles';
import { userSelector } from '../store/models/userModel';
import { Tooltip } from 'react-tooltip';
import clsx from 'clsx';

interface IAddProfessional {
    onClose: () => void,
    isOnboarding?: boolean,
}

function AddProfessional({ onClose, isOnboarding }: IAddProfessional) {

    const { isMobile } = useIsMobile()
    const dispatch = useDispatch();

    const { selectedShop, shopsData } = useSelector(shopSelector);
    const { id } = useSelector(userSelector);


    let client: AxiosInstance;

    if (process.env.NODE_ENV === "development") {
        client = axios.create({
            baseURL: "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
        })
    } else {
        client = axios.create({
            baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
        })
    }

    const [loading, setLoading] = useState(false)

    const [roles, setRoles] = useState<string[]>([])

    const emailRef = useRef<any>()

    useEffect(() => {
        // console.log("roles", roles)


    }, [roles])


    return (
        <div className={clsx(
            "w-full md:w-[24rem] h-full bg-white rounded-lg p-4 overflow-auto flex flex-col",
            isOnboarding && "border-2 border-gray-200"
        )}>
            <Tooltip anchorSelect=".help-owner" place="top">
                Owners have all permissions and can perform every action on the business dashboard
            </Tooltip>
            <Tooltip anchorSelect=".help-admin" place="top">
                Admins have permission to perform most actions on the business dashboard, with some restrictions such as payment methods.
            </Tooltip>
            <Tooltip anchorSelect=".help-staff" place="top">
                Staff can perform the services and are the ones the clients will see when booking. This can be barbers, hair dressers etc.
            </Tooltip>
            {!isOnboarding &&
                <div className='flex justify-end cursor-pointer' onClick={onClose}>
                    <Icon.X />
                </div>
            }

            <div className='w-full flex justify-center'>
                <p className="mb-2 text-lg font-semibold">
                    Invite a user
                </p>
            </div>
            {!isOnboarding &&
                <div className=''>

                    <>
                        <div className='w-full'>
                            <p className="mb-2 text-sm text-gray-600">
                                Enter the email address of the user you wish to add. We'll send them an invitation to join this business
                            </p>
                            <p className="mb-2 text-sm text-gray-600">
                                You'll be notified when they accept the invitation.
                            </p>
                        </div>

                    </>
                </div>
            }

            <div className={`mb-2`}>

                <TextInput
                    placeholder='user@email.com'
                    onChange={() => {
                        // setReset(false)
                        // setUserNotFound(false)
                    }} ref={emailRef} label='Email address' />


            </div>

            <div>
                <div className='text-md font-medium mb-2'>
                    Roles
                </div>
                <div className={clsx(
                    isOnboarding && "flex flex-row justify-between items-start"
                )}>
                    <div>
                        <div className='text-md underline'>
                            Admin roles
                        </div>
                        {!isOnboarding &&

                            <p className='text-sm mb-2 text-gray-500'>
                                They're responsible for the management of the business. They can add users, services and more.
                                {/* <span><a className='text-xs cursor-pointer hover:underline'>Learn more</a></span> */}
                            </p>
                        }

                        {!isOnboarding &&

                            <div className='flex flex-row items-center'>
                                <span className='mr-2'>
                                    <input disabled={roles.includes("admin")} type='checkbox' onChange={(change) => {
                                        if (change.target.checked) {
                                            // console.log("owner added");
                                            setRoles((prevRoles) => {
                                                return [...prevRoles, "owner"]
                                            })
                                        } else {
                                            const rolesTemp = roles.map(role => role);

                                            rolesTemp.splice(rolesTemp.indexOf("owner"), 1);

                                            setRoles(rolesTemp);
                                        }
                                    }}></input>
                                </span>
                                Owner
                                <span className='help-owner pl-2'><Icon.Info size={16} color='#667085' /></span>

                            </div>
                        }

                        <div className='flex flex-row items-center'>
                            <span className='mr-2'>
                                <input disabled={roles.includes("owner")} type='checkbox' onChange={(change) => {
                                    if (change.target.checked) {
                                        console.log("admin added");
                                        setRoles((prevRoles) => {
                                            return [...prevRoles, "admin"]
                                        })
                                    } else {

                                        const rolesTemp = roles.map(role => role);

                                        rolesTemp.splice(rolesTemp.indexOf("admin"), 1);


                                        setRoles(rolesTemp);
                                    }
                                }}></input>
                            </span>
                            Admin
                            <span className='help-admin pl-2'><Icon.Info size={16} color='#667085' /></span>
                        </div>

                    </div>


                    <div>
                        <div className={clsx(
                            'text-md underline',
                            !isOnboarding && "mt-6"
                        )}>
                            Regular roles
                        </div>
                        {!isOnboarding &&

                            <p className='text-sm mb-2 text-gray-500'>
                                They have an access limited to their function  in the business
                                {/* <span><a className=''>Learn more</a></span> */}
                            </p>
                        }
                        <div className='flex flex-row items-center'>
                            <span className='mr-2'>
                                <input type='checkbox' onChange={(change) => {
                                    if (change.target.checked) {
                                        console.log("staff added");
                                        setRoles((prevRoles) => {
                                            return [...prevRoles, "staff"]
                                        })
                                    } else {

                                        const rolesTemp = roles.map(role => role);

                                        rolesTemp.splice(rolesTemp.indexOf("staff"), 1);

                                        setRoles(rolesTemp);
                                    }
                                }}></input>
                            </span>
                            Staff
                            <span className='help-staff pl-2'><Icon.Info size={16} color='#667085' /></span>
                        </div>
                    </div>
                </div>

            </div>


            <div className='flex justify-center pt-4'>
                <Button loading={loading} size='sm' disabled={roles.length === 0} onClick={() => {
                    setLoading(true);
                    client.post("/inviteUser", {
                        "inviter": id,
                        "businessID": shopsData[selectedShop].id,
                        "roles": roles,
                        "email": emailRef.current.getInputText()
                    }).then((response) => {
                        console.log("Could accept.", response);
                        showToast("Invitation sent to " + emailRef.current.getInputText(), "success")
                        setLoading(false);
                    }).catch((e) => {
                        console.log("Couldn't accept.", e)
                        setLoading(false);
                        showToast("Couldn't send invitation", "error", 7000)
                    });

                    console.log({
                        "inviter": id,
                        "businessID": shopsData[selectedShop].id,
                        "roles": roles,
                        "email": emailRef.current.getInputText()
                    })
                }}>
                    {loading ? <>
                        <div className='flex items-center h-full w-full justify-center'>
                            <Oval color='#ffffff' secondaryColor='#7D7D7D' width={24} />
                        </div>
                    </> : <>
                        Send invitation
                    </>}
                </Button>
            </div>
        </div>



    )
}

export default AddProfessional