import { createModel } from '@rematch/core'
import { RootState } from '../store'
import { RootModel } from './index'
import { Service } from '../../types'

// export interface serviceType {
//     Description: string
//     Name: string
//     Price: string
//     Duration: string
//     Images: Array<string>
// }

export const Services = createModel<RootModel>()({
    state: {} as Service,
    reducers: {
        saveServices(state, payload) {
            return payload
        },

    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        // async incrementAsync(payload, rootState) {
        //   await new Promise((resolve) => setTimeout(resolve, 1000));
        //   dispatch.count.increment(payload);
        // },
    }),
})

export const serviceSelector = (state: RootState) => state.Services
