import { useSelector } from 'react-redux'
import { AppointmentSelector } from '../../store/models/appointmentsModel'
import AppointmentsPage from '../../components/AppointmentsPage'

const BookingPage = () => {

    const { upcoming }: any = useSelector(AppointmentSelector)
    return (
        <AppointmentsPage title='Upcoming appointments' data={upcoming} listTitle={'Appointments'} type={'upcoming'} />
    )   
}

export default BookingPage
